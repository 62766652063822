// HMIData 工程管理js
//
//  依赖
//
//  历史
//      创建    LEE     2020/12/24
//=============================================================================
import HG from './HMIGlobal.js'
import HMIInterface from './HMIInterface.js'
import HMIDataPool from './HMIDataPool.js'
import HMIPlanData from './HMIPlanData.js'
import HMIOperateCtrlMG from './HMIOperateCtrlMG.js'
import HMIDOMCtrlMG from './HMIDOMCtrlMG.js'
import $api from '../../http/api'
import $store from '../../store/store'
import { Message,Loading } from 'element-ui';
import HMIDraw from './HMIDraw.js'
import gInterface from "./HMIInterface";
import HMIPrj from "./HMIProject.js";

//-----------------------------------------------------------------------------
// 当前项目文件版本
//
const PRJ_FILE_VERSION = 1.10;
//-----------------------------------------------------------------------------
// 项目数据
//
var vHMIPrj = {
    // 项目属性
    setting: {
        width: 1280,
        height: 900,
        deviceZoom: 1,
    },
    // 当前项目中选中的页面
    curPg: 0,
    // LEE 2022/04/25 项目启动画面 
    startPg: 0,
    // 项目页面详细
    allPg: [
        {
            type: HG.PageType.blank,
            idx: 0,
            name: '新页面1',
            bgColor: '#FFFFFF',
            bgImage: '',
            ctrls: [],
            maxCtrlID: 0,
            selectedCnt: 0,
            offside: { x: 10, y: 8 }
        },
    ],
    // 项目是否改变
    modified: false,
    // 绘制是否需要刷新
    refreshDraw: true,
    // 所有的图片地址
    allImgs: [],
    // 当前项目文件版本
    version: PRJ_FILE_VERSION,
    // 系统权限 
    //   权限6最高, 权限1最低
    //   高级的权限能执行低级权限的任何功能
    sysPermissions: [ '1111', '2222', '3333', '4444', '5555', '6666' ],
    // 当前登录权限 
    //  0: 未登录权限; 
    //  1-6对应系统权限的密码sysPermissions[0]-sysPermissions[5].
    //  其他值无效
    curLoginPermission: 0,
    //
    // 临时用下拉列表关联控件指针
    // 
    curDdlLinked: { ctrl: null, x: 0, y: 0, w: 0, h: 0, start: 0, max: 0 },
};
//-----------------------------------------------------------------------------
// 项目通讯数据
//
var vHMIComm = {
    // 设备关联
    devices: [
    ],
    // 通讯
    comms: [
    ],
};
//-----------------------------------------------------------------------------
// 项目函数处理
//
var vHMIPrjFunc = {
    //-----------------------------------------------------------------------------
    // 历史曲线外部句柄
    //
    historyHandler: null,
    //-----------------------------------------------------------------------------
    // 输入控件外部句柄
    //
    inputControlHandler: null,
    //-----------------------------------------------------------------------------
    // 系统密码输入外部句柄
    //
    systemPermissionHandler: null,
    //-----------------------------------------------------------------------------
    // 控件布局操作
    //
    setCtrlsLayout: function (layoutType) {
        let vChanged = false;

        // 控件布局类型
        switch (layoutType) {
            case HG.LayoutType.left:
                // 左对齐
                vChanged = this.setCtrlsAlign(layoutType);
                break;
            case HG.LayoutType.right:
                // 右对齐
                vChanged = this.setCtrlsAlign(layoutType);
                break;
            case HG.LayoutType.top:
                // 上对齐
                vChanged = this.setCtrlsAlign(layoutType);
                break;
            case HG.LayoutType.bottom:
                // 下对齐
                vChanged = this.setCtrlsAlign(layoutType);
                break;
            case HG.LayoutType.first:
                // 最顶层
                vChanged = this.setCtrlLevel(layoutType);
                break;
            case HG.LayoutType.last:
                // 最底层
                vChanged = this.setCtrlLevel(layoutType);
                break;
            case HG.LayoutType.prev:
                // 上一层
                vChanged = this.setCtrlLevel(layoutType);
                break;
            case HG.LayoutType.next:
                // 下一层
                vChanged = this.setCtrlLevel(layoutType);
                break;
            case HG.LayoutType.vcenter:
                // 垂直居中
                vChanged = this.setCtrlPos(layoutType);
                break;
            case HG.LayoutType.hcenter:
                // 水平居中
                vChanged = this.setCtrlPos(layoutType);
                break;
            case HG.LayoutType.vsame:
                // 垂直等距
                vChanged = this.setCtrlPos(layoutType);
                break;
            case HG.LayoutType.hsame:
                // 水平等距
                vChanged = this.setCtrlPos(layoutType);
                break;
            case HG.LayoutType.samew:
                // 相同宽度
                vChanged = this.setCtrlSize(layoutType);
                break;
            case HG.LayoutType.sameh:
                // 相同高度
                vChanged = this.setCtrlSize(layoutType);
                break;
            case HG.LayoutType.samesizel:
                // 最大相同尺寸
                vChanged = this.setCtrlSize(layoutType);
                break;
            case HG.LayoutType.samesizem:
                // 最小相同尺寸
                vChanged = this.setCtrlSize(layoutType);
                break;
        }

        // 布局操作后改变
        return vChanged;
    },
    // 设置选中控件对齐
    setCtrlsAlign: function (layoutType) {
        // 控件尺寸处理
        let rect = { l: 0, r: 0, t: 0, b: 0 };
        //let first = true;
        let vCtrl = null;
        let idx = 0;
        let vChanged = false;
        // 选中控件索引
        for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查选中
            if (vCtrl.selected) {
                // 检查是否为主选控件
                if (vCtrl.mainSel) {
                    // 复制位置
                    rect.l = vCtrl.x;
                    rect.r = vCtrl.x + vCtrl.w;
                    rect.t = vCtrl.y;
                    rect.b = vCtrl.y + vCtrl.h;
                    // 退出
                    break;
                }
                /*
                // 检查控件
                if (first) {
                    // 设置标志
                    first = false;
                    // 复制位置
                    rect.l = vCtrl.x;
                    rect.r = vCtrl.x + vCtrl.w;
                    rect.t = vCtrl.y;
                    rect.b = vCtrl.y + vCtrl.h;
                } else {
                    // 位置处理
                    rect.l = Math.min(rect.l, vCtrl.x);
                    rect.r = Math.max(rect.r, (vCtrl.x + vCtrl.w));
                    rect.t = Math.min(rect.t, vCtrl.y);
                    rect.b = Math.max(rect.b, (vCtrl.y + vCtrl.h));
                }
                */
            }
        }

        // 选中控件索引
        for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查选中
            if (vCtrl.selected) {
                // 控件布局类型
                switch (layoutType) {
                    case HG.LayoutType.left:
                        // 检查是否改变
                        if (vCtrl.x != rect.l) {
                            // 左对齐
                            vCtrl.x = rect.l;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.right:
                        // 检查是否改变
                        if (vCtrl.x != (rect.r - vCtrl.w)) {
                            // 右对齐
                            vCtrl.x = rect.r - vCtrl.w;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.top:
                        // 检查是否改变
                        if (vCtrl.y != rect.t) {
                            // 上对齐
                            vCtrl.y = rect.t;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.bottom:
                        // 下对齐
                        // vCtrl.y = rect.b - vCtrl.h;
                        // break;
                        // 检查是否改变
                        if (vCtrl.y != (rect.b - vCtrl.h)) {
                            // 下对齐
                            vCtrl.y = rect.b - vCtrl.h;
                            // 改变
                            vChanged = true;
                        }
                        break;
                }
            }
        }

        // 操作时改变
        return vChanged;
    },
    // 设置选中控件层次位置
    setCtrlLevel: function (layoutType) {
        // 获取控件
        let vCtrl = null;
        let vIdxDest = -1;
        let vIdxSelected = -1;
        let idx = 0;
        let vChanged = false;
        // 检查是否为单选
        if (1 == vHMIPrj.allPg[vHMIPrj.curPg].selectedCnt) {
            // 选中控件索引
            for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 检查选中
                if (vCtrl.selected) {
                    // 获取选中控件的所有
                    vIdxSelected = idx;
                    break;
                }
            }
            // 检查控件选中
            if (vIdxSelected >= 0) {
                // 控件布局类型
                switch (layoutType) {
                    case HG.LayoutType.first:
                        // 控件目标索引
                        vIdxDest = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1;
                        // 检查是否一致
                        if (vIdxSelected != vIdxDest) {
                            // 获取
                            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected];
                            // 移动控件
                            for (idx = vIdxSelected; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx++) {
                                // 控件复制
                                vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx] = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx + 1];
                            }
                            // 设置
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxDest] = vCtrl;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.last:
                        // 控件目标索引
                        vIdxDest = 0;
                        // 检查是否一致
                        if (vIdxSelected != vIdxDest) {
                            // 获取
                            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected];
                            // 移动控件
                            for (idx = vIdxSelected; idx > 0; idx--) {
                                // 控件复制
                                vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx] = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx - 1];
                            }
                            // 设置
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[0] = vCtrl;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.prev:
                        // 目标索引
                        vIdxDest = vIdxSelected + 1;
                        // 检查目标索引
                        if (vIdxDest > vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1) {
                            vIdxDest = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1;
                        }
                        // 检查是否一致
                        if (vIdxSelected != vIdxDest) {
                            // 获取
                            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected];
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected] = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxDest];
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxDest] = vCtrl;
                            // 改变
                            vChanged = true;
                        }
                        break;
                    case HG.LayoutType.next:
                        // 目标索引
                        vIdxDest = vIdxSelected - 1;
                        // 检查目标索引
                        if (vIdxDest < 0) {
                            vIdxDest = 0;
                        }
                        // 检查是否一致
                        if (vIdxSelected != vIdxDest) {
                            // 获取
                            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected];
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxSelected] = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxDest];
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vIdxDest] = vCtrl;
                            // 改变
                            vChanged = true;
                        }
                        break;
                }
            }
        }
        // 改变
        return vChanged;
    },
    // 设置选中控件的位置
    setCtrlPos: function (layoutType) {
        // 获取控件的位置
        let vCtrlPos = [];
        let vCtrl = null;
        let vMaxRect  = { l: -1, t: -1, r: -1, b: -1 };
        let vMainRect = { l: -1, t: -1, r: -1, b: -1 };
        let vFirst = true;
        let vData = 0;
        let vMinIndex = 0;
        let idx = 0, idxSub = 0;
        let vChanged = false;
        let vDataPos = 0;
        // 检查是否为多选控件
        if (vHMIPrj.allPg[vHMIPrj.curPg].selectedCnt > 1) {
            // 选中控件索引
            for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 检查选中
                if (vCtrl.selected) {
                    // 添加控件的位置
                    vCtrlPos.push({ x: vCtrl.x, y: vCtrl.y, w: vCtrl.w, h: vCtrl.h, index: idx });
                    // 检查是否为主选控件
                    if (vCtrl.mainSel) {
                        // 复制位置
                        vMainRect.l = vCtrl.x;
                        vMainRect.r = vCtrl.x + vCtrl.w;
                        vMainRect.t = vCtrl.y;
                        vMainRect.b = vCtrl.y + vCtrl.h;
                    }
                    // 检查是否为首个控件
                    if (vFirst) {
                        // 设置标志
                        vFirst = false;
                        // 设置区域
                        vMaxRect.l = vCtrl.x;
                        vMaxRect.t = vCtrl.y;
                        vMaxRect.r = vCtrl.x + vCtrl.w;
                        vMaxRect.b = vCtrl.y + vCtrl.h;
                    }
                    else {
                        // 设置区域
                        vMaxRect.l = Math.min(vCtrl.x, vMaxRect.l);
                        vMaxRect.t = Math.min(vCtrl.y, vMaxRect.t);
                        vMaxRect.r = Math.max((vCtrl.x + vCtrl.w), vMaxRect.r);
                        vMaxRect.b = Math.max((vCtrl.y + vCtrl.h), vMaxRect.b);
                    }
                }
            }

            // 控件位置
            switch (layoutType) {
                case HG.LayoutType.vcenter:
                    // 中间值
                    vData = (vMainRect.l + vMainRect.r) / 2;
                    // 垂直居中
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 数据位置
                        vDataPos = Math.round(vData - (vCtrlPos[idx].w / 2));
                        // 检查是否相同位置
                        if (vDataPos != vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].x) {
                            // 设置控件位置
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].x = vDataPos;
                            // 改变
                            vChanged = true;
                        }
                    }
                    break;
                case HG.LayoutType.hcenter:
                    // 中间值
                    vData = (vMainRect.t + vMainRect.b) / 2;
                    // 水平居中
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 数据位置
                        vDataPos = Math.round(vData - (vCtrlPos[idx].h / 2));
                        // 检查是否相同位置
                        if (vDataPos != vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].y) {
                            // 设置控件位置
                            vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].y = vDataPos;
                            // 改变
                            vChanged = true;
                        }
                    }
                    break;
                case HG.LayoutType.vsame:
                    // 统计高度
                    vData = 0;
                    // 垂直等距
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 计算区域
                        vData += vCtrlPos[idx].h;
                    }
                    // 计算中间间隔
                    vData = Math.round((vMaxRect.b - vMaxRect.t - vData) / (vCtrlPos.length - 1));
                    // 清空
                    vCtrl = null;
                    // 排列位置
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 最小值索引
                        vMinIndex = idx;
                        // 排列
                        for (idxSub = idx + 1; idxSub < vCtrlPos.length; idxSub++) {
                            // 比较位置
                            if (vCtrlPos[vMinIndex].y > vCtrlPos[idxSub].y) {
                                // 设置最小索引
                                vMinIndex = idxSub;
                            }
                        }
                        // 设置最小索引
                        if (vMinIndex != idx) {
                            // 互换数据
                            vCtrl = vCtrlPos[vMinIndex];
                            vCtrlPos[vMinIndex] = vCtrlPos[idx];
                            vCtrlPos[idx] = vCtrl;
                        }
                        // 检查索引
                        if (idx > 0) {
                            // 前控件的位置
                            let prevY = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx - 1].index].y;
                            let prevH = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx - 1].index].h;
                            // 数据位置
                            vDataPos = prevY + prevH + vData;
                            // 检查位置
                            if (vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].y != vDataPos) {
                                // 设置位置
                                vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].y = vDataPos;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
                case HG.LayoutType.hsame:
                    // 统计高度
                    vData = 0;
                    // 垂直等距
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 计算区域
                        vData += vCtrlPos[idx].w;
                    }
                    // 计算中间间隔
                    vData = Math.round((vMaxRect.r - vMaxRect.l - vData) / (vCtrlPos.length - 1));
                    // 清空
                    vCtrl = null;
                    // 排列位置
                    for (idx = 0; idx < vCtrlPos.length; idx++) {
                        // 最小值索引
                        vMinIndex = idx;
                        // 排列
                        for (idxSub = idx + 1; idxSub < vCtrlPos.length; idxSub++) {
                            // 比较位置
                            if (vCtrlPos[vMinIndex].x > vCtrlPos[idxSub].x) {
                                // 设置最小索引
                                vMinIndex = idxSub;
                            }
                        }
                        // 设置最小索引
                        if (vMinIndex != idx) {
                            // 互换数据
                            vCtrl = vCtrlPos[vMinIndex];
                            vCtrlPos[vMinIndex] = vCtrlPos[idx];
                            vCtrlPos[idx] = vCtrl;
                        }
                        // 检查索引
                        if (idx > 0) {
                            // 前控件的位置
                            let prevX = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx - 1].index].x;
                            let prevW = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx - 1].index].w;
                            // 数据位置
                            vDataPos = prevX + prevW + vData;
                            // 检查位置
                            if (vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].x != vDataPos) {
                                // 设置位置
                                vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlPos[idx].index].x = vDataPos;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
            }
        }
        // 改变
        return vChanged;
    },
    // 设置选中控件的尺寸
    setCtrlSize: function (layoutType) {
        // 获取控件的尺寸
        let vCtrl = null;
        let vMaxRect = { w: -1, h: -1 };
        let vMinRect = { w: -1, h: -1 };
        let vFirst = true;
        let idx = 0;
        let vChanged = false;
        // 检查是否为多选控件
        if (vHMIPrj.allPg[vHMIPrj.curPg].selectedCnt > 1) {
            // 选中控件索引
            for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 检查选中
                if (vCtrl.selected) {
                    // 检查是否为首个控件
                    if (vFirst) {
                        // 设置标志
                        vFirst = false;
                        // 设置区域
                        vMaxRect.w = vCtrl.w;
                        vMaxRect.h = vCtrl.h;
                        vMinRect.w = vCtrl.w;
                        vMinRect.h = vCtrl.h;
                    }
                    else {
                        // 设置区域
                        vMaxRect.w = Math.max(vCtrl.w, vMaxRect.w);
                        vMaxRect.h = Math.max(vCtrl.h, vMaxRect.h);
                        vMinRect.w = Math.min(vCtrl.w, vMinRect.w);
                        vMinRect.h = Math.min(vCtrl.h, vMinRect.h);
                    }
                }
            }

            // 控件尺寸
            switch (layoutType) {
                case HG.LayoutType.samew:
                    // 选中控件索引
                    for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                        // 获取控件
                        vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                        // 检查选中
                        if (vCtrl.selected) {
                            // 相同宽度
                            if (vCtrl.w != vMaxRect.w) {
                                // 改变
                                vCtrl.w = vMaxRect.w;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
                case HG.LayoutType.sameh:
                    // 选中控件索引
                    for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                        // 获取控件
                        vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                        // 检查选中
                        if (vCtrl.selected) {
                            // 相同高度
                            if (vCtrl.h != vMaxRect.h) {
                                // 改变
                                vCtrl.h = vMaxRect.h;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
                case HG.LayoutType.samesizel:
                    // 选中控件索引
                    for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                        // 获取控件
                        vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                        // 检查选中
                        if (vCtrl.selected) {
                            // 不同宽度, 高度
                            if ((vCtrl.w != vMaxRect.w) || (vCtrl.h != vMaxRect.h)) {
                                // 相同宽度
                                vCtrl.w = vMaxRect.w;
                                // 相同高度
                                vCtrl.h = vMaxRect.h;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
                case HG.LayoutType.samesizem:
                    // 选中控件索引
                    for (idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
                        // 获取控件
                        vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                        // 检查选中
                        if (vCtrl.selected) {
                            // 不同宽度, 高度
                            if ((vCtrl.w != vMinRect.w) || (vCtrl.h != vMinRect.h)) {
                                // 相同宽度
                                vCtrl.w = vMinRect.w;
                                // 相同高度
                                vCtrl.h = vMinRect.h;
                                // 改变
                                vChanged = true;
                            }
                        }
                    }
                    break;
            }
        }
        // 是否改变
        return vChanged;
    },
    //-----------------------------------------------------------------------------
    // 控件选择操作相关
    //
    // 区域相交
    interCrossCtrl: function (Ax0, Ay0, Ax1, Ay1,
        Bx0, By0, Bx1, By1) {
        // 不相交情况
        if (Ax1 < Bx0) return false;
        if (Ax0 > Bx1) return false;
        if (Ay1 < By0) return false;
        if (Ay0 > By1) return false;
        // 检查控件是否相交
        if (((Ax0 <= Bx0) && (Ax1 >= Bx0) && (Ay0 <= By0) && (Ay1 >= By0)) ||
            ((Ax0 <= Bx0) && (Ax1 >= Bx0) && (Ay0 <= By1) && (Ay1 >= By1)) ||
            ((Ax0 <= Bx1) && (Ax1 >= Bx1) && (Ay0 <= By0) && (Ay1 >= By0)) ||
            ((Ax0 <= Bx1) && (Ax1 >= Bx1) && (Ay0 <= By1) && (Ay1 >= By1))) {
            return true;
        }
        // 检查控件是否相交
        if (((Bx0 <= Ax0) && (Bx1 >= Ax0) && (By0 <= Ay0) && (By1 >= Ay0)) ||
            ((Bx0 <= Ax0) && (Bx1 >= Ax0) && (By0 <= Ay1) && (By1 >= Ay1)) ||
            ((Bx0 <= Ax1) && (Bx1 >= Ax1) && (By0 <= Ay0) && (By1 >= Ay0)) ||
            ((Bx0 <= Ax1) && (Bx1 >= Ax1) && (By0 <= Ay1) && (By1 >= Ay1))) {
            return true;
        }
        // 检查控件是否相交
        if ((((Ax0 <= Bx0) && (Ax1 >= Bx0) && (Ax0 <= Bx1) && (Ax1 >= Bx1)) ||
            ((Bx0 <= Ax0) && (Bx1 >= Ax0) && (Bx0 <= Ax1) && (Bx1 >= Ax1))) &&
            (((Ay0 <= By0) && (Ay1 >= By1) && (Ay0 <= By0) && (Ay1 >= By1)) ||
            ((By0 <= Ay0) && (By1 >= Ay0) && (By0 <= Ay1) && (By1 >= Ay1)))) {
            return true;
        }
        return false;
    },
    // 选中所有控件
    selectAllCtrls: function (selected) {
        // 控件内容
        var vCtrl = null;
        var vSelectedCnt = 0;
        // 索引
        var idx = 0;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 设置选中状态
            vCtrl.selected = selected;

            // 选中控件数量
            vSelectedCnt++;
        }
        // 选中控件数量
        this.setSelectedCtrlsCnt(vSelectedCnt);
    },
    // 选中控件
    selectCtrl: function (x0, y0, x1, y1, shift) {
        // 控件内容
        var vCtrl = null;
        var vCtrlLast = null;
        var vSelectedCnt = 0;
        // 索引
        var idx = 0;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查控件是否为主选择控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            if (vCtrl.mainSel) {
                // 重置主选择控件数据
                vCtrl.mainSel = false;
            }
            // 区域相交
            if (this.interCrossCtrl(x0, y0, x1, y1, vCtrl.x, vCtrl.y, (vCtrl.x + vCtrl.w), (vCtrl.y + vCtrl.h))) {
                // 检查shift按键是否按下
                if (shift) {
                    // 设置控件选中
                    vCtrl.selected = !vCtrl.selected;
                }
                else {
                    // 设置控件选中
                    vCtrl.selected = true;
                }
            }
            else {
                // 检查shift按键是否按下
                if (!shift) {
                    // 设置控件选中
                    vCtrl.selected = false;
                }
            }
            // 设置控件选中
            if (vCtrl.selected) {
                // 获取控件
                vCtrlLast = vCtrl;
                // 选中控件数量
                vSelectedCnt++;
            }
        }
        // 获取控件
        if (vCtrlLast) {
            // 设置为主控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            // 重置主选择控件数据
            vCtrlLast.mainSel = true;
        }
        // 选中控件数量
        this.setSelectedCtrlsCnt(vSelectedCnt);
    },
    // 选中控件设置为主选择控件
    //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
    setMainSelCtrl: function (x, y) {
        // 控件内容
        var vCtrl = null;
        var vCtrlLast = null;
        var vCtrlLastSel = null;
        // 索引
        var idx = 0;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查控件是否为主选择控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            if (vCtrl.mainSel) {
                // 重置主选择控件数据
                vCtrl.mainSel = false;
                // 上次选中控件
                vCtrlLastSel = vCtrl;
            }
            // 区域相交
            if ((x >= vCtrl.x) && (x <= (vCtrl.x + vCtrl.w)) && (y >= vCtrl.y) && (y <= (vCtrl.y + vCtrl.h))) {
                // 检查控件选中
                if (vCtrl.selected) {
                    // 设置控件
                    vCtrlLast = vCtrl;
                }
            }
        }

        // 检查选中控件是否存在
        if (vCtrlLast) {
            // 设置为主控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            // 重置主选择控件数据
            vCtrlLast.mainSel = true;
        } else {
            if(vCtrlLastSel) {
                // 设置为主控件
                //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
                // 重置主选择控件数据
                vCtrlLastSel.mainSel = true;
            }
        }
    },
    // 点击选中控件
    pointCtrl: function (x, y, shift) {
        // 控件内容
        var vCtrl = null;
        var vCtrlLast = null;
        var vSelectedCtrl = null;
        var vSelectedCnt = 0;
        // 索引
        var idx = 0;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查控件是否为主选择控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            if (vCtrl.mainSel) {
                // 重置主选择控件数据
                vCtrl.mainSel = false;
            }
            // 区域相交
            if ((x >= vCtrl.x) && (x <= (vCtrl.x + vCtrl.w)) && (y >= vCtrl.y) && (y <= (vCtrl.y + vCtrl.h))) {
                // 检查shift按键是否按下
                if (shift) {
                    // 设置控件选中
                    vCtrl.selected = !vCtrl.selected;
                }
                else {
                    // 设置选中控件
                    vSelectedCtrl = vCtrl;
                }
                break;
            }
        }
        // 检查选中控件是否存在
        if (!shift) {
            // 检查选中控件
            for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 区域相交
                if (vSelectedCtrl == vCtrl) {
                    // 设置选中
                    vCtrl.selected = true;
                }
                else {
                    // 设置选中
                    vCtrl.selected = false;
                }
                // 设置控件选中
                if (vCtrl.selected) {
                    // 获取控件
                    vCtrlLast = vCtrl;
                    // 选中控件数量
                    vSelectedCnt++;
                }
            }
        } else {
            // 检查选中控件
            for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 设置控件选中
                if (vCtrl.selected) {
                    // 获取控件
                    vCtrlLast = vCtrl;
                    // 选中控件数量
                    vSelectedCnt++;
                }
            }
        }
        // 获取控件
        if (vCtrlLast) {
            // 设置为主控件
            //  [mainSel 属性表示多选控件中的主控件，对齐参考控件]
            // 重置主选择控件数据
            vCtrlLast.mainSel = true;
        }
        // 选中控件数量
        this.setSelectedCtrlsCnt(vSelectedCnt);
    },
    // 选择位置在控件改变尺寸四周
    pointInChangedRect: function (x, y, destX, destY) {
        // 选择位置
        if ((x >= destX - HG.$Set.allSettings.selectedFrameSetting.halfSize) &&
            (x <= destX + HG.$Set.allSettings.selectedFrameSetting.halfSize) &&
            (y >= destY - HG.$Set.allSettings.selectedFrameSetting.halfSize) &&
            (y <= destY + HG.$Set.allSettings.selectedFrameSetting.halfSize)) {
            // 在选择框中
            return true;
        }
        return false;
    },
    // 左键点击已经选中控件
    pointSelectedCtrl: function (x, y, shift) {
        // 控件内容
        var vCtrl = null;
        // 选中状态
        var vSelectedStatus = { selected: false, status: 0 };
        // 索引
        var idx = 0;

        // 检查控件是否有效
        if (!vHMIPrj.allPg[vHMIPrj.curPg].ctrls) {
            // 返回
            return;
        }

        // 检查选中控件是否存在
        if (!shift) {
            // 检查控件
            for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
                // 获取控件
                vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
                // 是否选中
                if (vCtrl.selected) {
                    // 选择框状态
                    if (this.pointInChangedRect(x, y, vCtrl.x, vCtrl.y)) {
                        // 检查此控件是否选中
                        vSelectedStatus.selected = true;
                        // 区域状态
                        vSelectedStatus.status = 1;
                    }
                    // 选择框状态
                    else if (this.pointInChangedRect(x, y, vCtrl.x + vCtrl.w, vCtrl.y)) {
                        // 检查此控件是否选中
                        vSelectedStatus.selected = true;
                        // 区域状态
                        vSelectedStatus.status = 2;
                    }
                    // 选择框状态
                    else if (this.pointInChangedRect(x, y, vCtrl.x + vCtrl.w, vCtrl.y + vCtrl.h)) {
                        // 检查此控件是否选中
                        vSelectedStatus.selected = true;
                        // 区域状态
                        vSelectedStatus.status = 3;
                    }
                    // 选择框状态
                    else if (this.pointInChangedRect(x, y, vCtrl.x, vCtrl.y + vCtrl.h)) {
                        // 检查此控件是否选中
                        vSelectedStatus.selected = true;
                        // 区域状态
                        vSelectedStatus.status = 4;
                    }
                }

                // 区域相交
                if ((x >= vCtrl.x) && (x <= (vCtrl.x + vCtrl.w)) && (y >= vCtrl.y) && (y <= (vCtrl.y + vCtrl.h))) {
                    // 检查此控件是否选中
                    vSelectedStatus.selected = true;
                    // 区域状态
                    vSelectedStatus.status = 0;
                }

                // 检查选中状态
                if (vSelectedStatus.selected) {
                    // 是否选中
                    if (!vCtrl.selected) {
                        // 未选中
                        vSelectedStatus.selected = false;
                        // 区域状态
                        vSelectedStatus.status = 0;
                    }
                    // 检查
                    break;
                }
            }
        }
        // 选中状态
        return vSelectedStatus;
    },
    // 移动已经选中控件
    moveSelectedCtrl: function (x, y) {
        // 控件内容
        var vCtrl = null;
        // 索引
        var idx = 0;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 区域相交
            if (vCtrl.selected) {
                // 移动位置
                vCtrl.x += x;
                vCtrl.y += y;
            }
        }

        // 项目改变
        this.setProjectModified();
    },
    // 改变已经选中控件尺寸
    changeSelectedCtrlSize: function (x, y, status) {
        // 控件内容
        var vCtrl = null;
        // 索引
        var idx = 0;
        // 底部与右侧
        var vRect = { l: 0, r: 0, t: 0, b: 0 };

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 区域相交
            if (vCtrl.selected) {
                // 底部与右侧
                vRect.l = vCtrl.x;
                vRect.r = vCtrl.x + vCtrl.w;
                vRect.t = vCtrl.y;
                vRect.b = vCtrl.y + vCtrl.h;
                // 检查状态
                switch (status) {
                    case 1:
                        // 移动位置
                        vRect.l += x;
                        vRect.t += y;
                        break;
                    case 2:
                        // 移动位置
                        vRect.t += y;
                        // 底部与右侧
                        vRect.r += x;
                        break;
                    case 3:
                        // 底部与右侧
                        vRect.r += x;
                        vRect.b += y;
                        break;
                    case 4:
                        // 移动位置
                        vRect.l += x;
                        // 底部与右侧
                        vRect.b += y;
                        break;
                    case 5:
                        // 控件移动
                        vRect.l += x;
                        vRect.t += y;
                        vRect.r += x;
                        vRect.b += y;
                        break;
                }
                // 位置重置
                vCtrl.x = Math.min(vRect.l, vRect.r);
                vCtrl.y = Math.min(vRect.t, vRect.b);
                vCtrl.w = Math.max(vRect.l, vRect.r) - vCtrl.x;
                vCtrl.h = Math.max(vRect.t, vRect.b) - vCtrl.y;
            }
        }

        // 项目改变
        this.setProjectModified();
    },
    //-----------------------------------------------------------------------------
    // 设置控件选中的数量
    //
    setSelectedCtrlsCnt: function (count) {
        // 选中控件数量
        vHMIPrj.allPg[vHMIPrj.curPg].selectedCnt = count;
        // 项目改变
        this.setProjectModified();
    },
    //-----------------------------------------------------------------------------
    // 获取控件选中的数量
    //
    getSelectedCtrlsCnt: function () {
        // 选中控件数量
        return vHMIPrj.allPg[vHMIPrj.curPg].selectedCnt;
    },
    //-----------------------------------------------------------------------------
    // 获取当前页面中所有选中的控件
    //
    getSelectedCtrls: function () {
        var vCtrlList   = [];
        let vCtrl       = null;
        // 选中控件索引
        for (let idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查选中
            if (vCtrl.selected) {
                // 添加到控件列表
                vCtrlList.push(vCtrl);
            }
        }

        // 添加到控件列表
        return vCtrlList;
    },
    
    //-----------------------------------------------------------------------------
    // 添加控件
    //
    addCtrl: function (ctrlType, vAddUndo=true) {
        // 控件数据
        var vCtrlData = null;
        // 获取内容
        for (var idx = 0; idx < HG.$Set.allCtrlsData.length; idx++) {
            // 检查控件是否
            if (ctrlType == HG.$Set.allCtrlsData[idx].type) {
                // 获取内容
                vCtrlData = JSON.parse(JSON.stringify(HG.$Set.allCtrlsData[idx]));
                break;
            }
        }
        console.log(vCtrlData);
        // 检查是否有效
        if (vCtrlData) {
            // 所有控件不选中
            this.selectAllCtrls(false);

            // 检查最大控件ID是否存在
            if (!vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID) {
                // 设置最大max ID
                vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length;
                // 设置ID
                for (let vCtrlIdx = 0; vCtrlIdx < vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID; vCtrlIdx++) {
                    // 设置每一个控件的ID
                    vHMIPrj.allPg[vHMIPrj.curPg].ctrls[vCtrlIdx].id = vCtrlIdx;
                }
            }

            // 设置偏移
            vCtrlData.x += vHMIPrj.allPg[vHMIPrj.curPg].offside.x;
            vCtrlData.y += vHMIPrj.allPg[vHMIPrj.curPg].offside.y;
            // 设置选中
            vCtrlData.selected = true;
            // 设置ID
            vCtrlData.id = vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID;
            // 添加数据
            vHMIPrj.allPg[vHMIPrj.curPg].ctrls.push(vCtrlData);
            // 修改偏移量
            vHMIPrj.allPg[vHMIPrj.curPg].offside.x += 10;
            vHMIPrj.allPg[vHMIPrj.curPg].offside.y += 10;
            // 检查是否超出边界
            if ((vHMIPrj.allPg[vHMIPrj.curPg].offside.x + vCtrlData.w) > vHMIPrj.setting.width) {
                // 修改偏移量
                vHMIPrj.allPg[vHMIPrj.curPg].offside.x = 0;
            }
            // 检查是否超出边界
            if ((vHMIPrj.allPg[vHMIPrj.curPg].offside.y + vCtrlData.h) > vHMIPrj.setting.height) {
                // 修改偏移量
                vHMIPrj.allPg[vHMIPrj.curPg].offside.y = 0;
            }
            // 最大控件ID递增
            vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID++;
            // 选中控件数量
            this.setSelectedCtrlsCnt(1);
            
            // 是否添加到撤销操作
            if(vAddUndo) {
                // 添加创建控件到操作中
                HMIOperateCtrlMG.addUndoCreatedCtrls(vHMIPrj.curPg, this.getSelectedCtrls());
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 添加控件从撤销、重做操作中
    //
    addCtrlFromOperates: function (controls) {
        // 控件数据
        let vCtrlData = null;

        // 检查控件数量
        if(controls.length > 0) {
            // 所有控件不选中
            this.selectAllCtrls(false);
            // 重新添加控件
            for(let idx = 0; idx < controls.length; idx++) {
                // 获取控件
                vCtrlData = JSON.parse(JSON.stringify(controls[idx]));
                // 添加控件
                vHMIPrj.allPg[vHMIPrj.curPg].ctrls.push(vCtrlData);
                // 设置控件选中状态
                vCtrlData.selected = true;
            }
            // 选中控件数量
            this.setSelectedCtrlsCnt(controls.length);
        }
    },
    //-----------------------------------------------------------------------------
    // 撤销、重做操作动作中的控件 选中
    //
    selectAllCtrlFromOperates: function (controls) {
        // 控件数据
        let vCtrlData       = null;
        let vCtrlDest       = null;
        let vSelectedCnt    = 0;

        // 检查控件数量
        if(controls.length > 0) {
            // 所有控件不选中
            this.selectAllCtrls(false);
            // 重新添加控件
            for(let idx = 0; idx < controls.length; idx++) {
                // 获取控件
                vCtrlData = controls[idx];
                // 检查是否为空
                if(vCtrlData) {
                    // 检查控件
                    for (let idxSub = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idxSub >= 0; idxSub--) {
                        // 获取控件
                        vCtrlDest = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idxSub];
                        // 检查是否有效
                        if(vCtrlDest) {
                            // 检查是否一致
                            if(vCtrlDest.id == vCtrlData.id) {
                                // 选中
                                vCtrlDest.selected = true;
                                // 选中控件数量
                                vSelectedCnt++;
                            }
                        }
                    }
                }
            }
            // 选中控件数量
            this.setSelectedCtrlsCnt(vSelectedCnt);
        }
    },
    //-----------------------------------------------------------------------------
    // 撤销、重做操作 删除的控件的操作中
    //
    deleteCtrlFromOperates: function (controls) {
        // 选中控件
        this.selectAllCtrlFromOperates(controls);
        // 删除选中
        this.deleteAllSelectedCtrls(false);
    },
    //-----------------------------------------------------------------------------
    // 撤销、重做 再次剪切的控件的操作中
    //
    cutCtrlFromOperates: function (controls) {
        // 选中控件
        this.selectAllCtrlFromOperates(controls);
        // 剪切选中
        this.cutAllSelectedCtrls(false);
    },
    //-----------------------------------------------------------------------------
    // 选中控件的属性改变
    //
    // 添加属性修改撤销
    selectedCtrlsModifyAttributes: function (refreshToolbar=false) {
        // 检查是否选择控件
        if(this.getSelectedCtrlsCnt() > 0) {
            // 添加属性修改撤销
            HMIOperateCtrlMG.addUndoModifyCtrls(vHMIPrj.curPg, this.getSelectedCtrls());
            // 检查是否刷新工具栏
            if(refreshToolbar) {
                // 刷新工具栏状态
                HG.$Set.projectStatus.toolbarStatus = true;
            }
            // 属性改变
            return true;
        }
        return false;
    },
    //-----------------------------------------------------------------------------
    // 修改控件的操作中
    //
    modifyCtrlFromOperates: function (controls, modify=true) {
        // 修改控件属性
        // 控件内容
        let vCtrl       = null;
        let vMapObj     = null;
        let vCtrlModify = null;
        let vCtrlMap    = new Map();
        let vCtrlSelect = false;
        // 检查控件
        for(let idxModify = 0; idxModify < controls.length; idxModify++) {
            // 设置控件影视
            vCtrlMap.set(controls[idxModify].id, {ctrl:controls[idxModify], arrayIdx:idxModify});
        }
        // 检查控件
        for (let idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 查找是否
            if (vCtrlMap.has(vCtrl.id)) {
                // 控件选中
                vCtrlSelect = vCtrl.selected;
                // 获取Map对象
                vMapObj = vCtrlMap.get(vCtrl.id);
                // 获取修改的控件
                vCtrlModify = vMapObj.ctrl;
                // 控件选中
                vCtrlModify.selected = vCtrlSelect;
                // 设置属性
                vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx] = vCtrlModify;
                // 检查是否修改
                if(modify) {
                    // 互换属性
                    controls[vMapObj.arrayIdx] = vCtrl;
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 清空复制偏移
    //
    clearCopyOffside: function () {
        // 控件内容
        let idx     = 0;
        let vPage   = null;

        // 设置偏移量
        for (idx = 0; idx < vHMIPrj.allPg.length; idx++) {
            // 获取页面
            vPage = vHMIPrj.allPg[idx];
            // 检查是否有效
            if (vPage) {
                // 设置偏移
                vPage.offside.x = 0;
                vPage.offside.y = 0;
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 清空复制控件
    //
    clearCopyControls: function () {
        // 控件内容
        let idx = 0;
        
        // 复制控件数量清空
        HG.$Set.allCopyData.count = 0;
        // 源页面
        HG.$Set.allCopyData.sourcePg = null;
        // 检查复制控件数量
        for (idx = HG.$Set.allCopyData.ctrls.length - 1; idx >= 0; idx--) {
            // 移除
            HG.$Set.allCopyData.ctrls.pop();
        }
        
        // 清除复制控件
        HG.$Set.allSettings.clearCopyCtrls();
    },
    //-----------------------------------------------------------------------------
    // 控件剪切、复制、粘贴、删除操作
    //
    // 复制选中所有控件
    copyAllSelectedCtrls: function () {
        // 控件内容
        let idx = 0;
        let vCtrl = null;
        let vExistCopy = false;

        // 复制控件数量清空
        HG.$Set.allCopyData.count = 0;
        // 源页面
        HG.$Set.allCopyData.sourcePg = null;
        // 检查复制控件数量
        for (idx = HG.$Set.allCopyData.ctrls.length - 1; idx >= 0; idx--) {
            // 移除
            HG.$Set.allCopyData.ctrls.pop();
        }

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查选中状态
            if (vCtrl.selected) {
                // 控件数据递增
                HG.$Set.allCopyData.count++;
                // 添加控件数据
                HG.$Set.allCopyData.ctrls.push(JSON.parse(JSON.stringify(vCtrl)));
                // 源页面
                HG.$Set.allCopyData.sourcePg = vHMIPrj.allPg[vHMIPrj.curPg];
                // 复制
                vExistCopy = true;
            }
        }

        // 复制
        if (vExistCopy) {
            // clear copy offside
            this.clearCopyOffside();
            // 复制控件
            HG.$Set.allSettings.copyControls(HG.$Set.allCopyData.count.toString(), 
                                             JSON.stringify(HG.$Set.allCopyData.ctrls));
        }
    },
    // 剪切
    cutAllSelectedCtrls: function (vAddUndo=true) {
        // 是否添加到撤销操作
        if(vAddUndo) {
            // 添加所有选中控件到操作中
            HMIOperateCtrlMG.addUndoCutCtrls(vHMIPrj.curPg, this.getSelectedCtrls());
        }
        // 复制选中所有控件
        this.copyAllSelectedCtrls();
        // 删除选中所有控件
        this.deleteAllSelectedCtrls(false);
    },
    // 粘贴
    pasteCtrls: function (vAddUndo=true) {
        let idx = 0;
        let vCtrlData = null;
        let vCookiesData = null;
        let offsideXOut = false;
        let offsideYOut = false;
        // 检查复制粘贴控件数量
        if(0 == HG.$Set.allCopyData.count) {
            // 加载粘贴控件数量
            vCookiesData = HG.$Set.allSettings.pasteControlsCnt();
            // 检查数据是否有效
            if ((vCookiesData != null) && (vCookiesData != '')) {
                // 转换数量
                HG.$Set.allCopyData.count = parseInt(vCookiesData);
                // 加载粘贴控件
                vCookiesData = HG.$Set.allSettings.pasteControls();
                // 转换数据
                HG.$Set.allCopyData.ctrls = JSON.parse(vCookiesData);
            }
        }
        // 检查复制粘贴数量
        if (HG.$Set.allCopyData.count > 0) {
            // 所有控件不选中
            this.selectAllCtrls(false);

            // 检查是否一致
            if (HG.$Set.allCopyData.ctrls.length === HG.$Set.allCopyData.count) {
                // 检查是否为相同的页面
                if (HG.$Set.allCopyData.sourcePg === vHMIPrj.allPg[vHMIPrj.curPg]) {
                    // 检查是否为初始状态
                    if ((0 === vHMIPrj.allPg[vHMIPrj.curPg].offside.x) &&
                        (0 === vHMIPrj.allPg[vHMIPrj.curPg].offside.y)) {
                        // 修改偏移量
                        vHMIPrj.allPg[vHMIPrj.curPg].offside.x += 10;
                        vHMIPrj.allPg[vHMIPrj.curPg].offside.y += 10;
                    }
                }

                // 超出限度
                offsideXOut = false;
                offsideYOut = false;

                // 遍历复制控件
                for (idx = HG.$Set.allCopyData.ctrls.length - 1; idx >= 0; idx--) {
                    // 获取控件
                    vCtrlData = JSON.parse(JSON.stringify(HG.$Set.allCopyData.ctrls[idx]));

                    // 控件Id设置为新
                    vCtrlData.id = vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID;
                    // 最大控件ID递增
                    vHMIPrj.allPg[vHMIPrj.curPg].maxCtrlID++;
                    // 设置偏移
                    vCtrlData.x += vHMIPrj.allPg[vHMIPrj.curPg].offside.x;
                    vCtrlData.y += vHMIPrj.allPg[vHMIPrj.curPg].offside.y;
                    // 设置选中
                    vCtrlData.selected = true;
                    // 添加数据
                    vHMIPrj.allPg[vHMIPrj.curPg].ctrls.push(vCtrlData);

                    // 检查是否超出边界
                    if ((vCtrlData.x + vCtrlData.w) > vHMIPrj.setting.width) {
                        // 超出限度
                        offsideXOut = true;
                    }
                    // 检查是否超出边界
                    if ((vCtrlData.y + vCtrlData.h) > vHMIPrj.setting.height) {
                        // 超出限度
                        offsideYOut = true;
                    }
                }

                // 检查是否超出
                if (offsideXOut) {
                    // 修改偏移量
                    vHMIPrj.allPg[vHMIPrj.curPg].offside.x = 0;
                }
                else {
                    // 修改偏移量
                    vHMIPrj.allPg[vHMIPrj.curPg].offside.x += 10;
                }
                // 检查是否超出
                if (offsideYOut) {
                    // 修改偏移量
                    vHMIPrj.allPg[vHMIPrj.curPg].offside.y = 0;
                }
                else {
                    // 修改偏移量
                    vHMIPrj.allPg[vHMIPrj.curPg].offside.y += 10;
                }
                
                // 是否添加到撤销操作
                if(vAddUndo) {
                    // 添加复制控件到操作中
                    HMIOperateCtrlMG.addUndoPasteCtrls(vHMIPrj.curPg, this.getSelectedCtrls());
                }
            }
        }
        // 项目改变
        this.setProjectModified();
    },
    // 删除选中所有控件
    deleteAllSelectedCtrls: function(vAddUndo=true) {
        // 控件内容
        let vCtrl = null;
        let vCtrlArray = [];
        let vDeletedCtrlArray = [];
        // 检查控件
        for (var idx = 0; idx < vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length; idx++) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 检查选中状态
            if (!vCtrl.selected) {
                // add to array
                vCtrlArray.push(vCtrl);
            } else {
                // 添加到删除
                vDeletedCtrlArray.push(vCtrl);
                // 删除关联的DOM控件
                HMIDOMCtrlMG.deleteLinkedDOMCtrls(vHMIPrj.curPg, vCtrl.id);
            }
        }
        // 是否添加到撤销操作
        if(vAddUndo) {
            // 添加所有删除控件到操作中
            HMIOperateCtrlMG.addUndoDeleteCtrls(vHMIPrj.curPg, vDeletedCtrlArray);
        }
        // 设置指针
        vHMIPrj.allPg[vHMIPrj.curPg].ctrls = vCtrlArray;
        // 选中控件数量
        this.setSelectedCtrlsCnt(0);
    },

    // getDecimalFrom:function (param,decimalPoint,decimalVos) {
    //     decimalVos.push({decimalPoint:decimalPoint,
    //     subOriginalId: param[1],
    //     paramId: param[2],
    
    // })
    // },
    //-----------------------------------------------------------------------------
    // 保存通讯文件
    //
    saveProjectCOMM: function () {
        let lPg, lCtrl;
        // 通讯数据
        let lCOMMData;
        // 通讯参数
        let lParams;
        let lDatas;
        // let decimalFrom={planID:HMIInterface.planID,
        //     decimalVos:[]
        // }
        // 通讯数据清空
        vHMIComm.devices = [];
        vHMIComm.comms = [];
        // 循环获取
        for (let vIdxPg = 0; vIdxPg < vHMIPrj.allPg.length; vIdxPg++) {
            // 获取页面
            lPg = vHMIPrj.allPg[vIdxPg];
            // 是否有效
            if (lPg) {
                // 遍历控件
                for (let vIdxCtrl = 0; vIdxCtrl < lPg.ctrls.length; vIdxCtrl++) {
                    // 获取控件
                    lCtrl = lPg.ctrls[vIdxCtrl];
                    // 清空
                    lParams = null;
                    lDatas = null;
                    // 是否有效
                    if (lCtrl) {

                        // 检查通讯数据是否存在
                        switch (lCtrl.type) {
                            case HG.ControlType.light:
                            case HG.ControlType.bbtn:
                            case HG.ControlType.wbtn:
                            case HG.ControlType.num:
                            case HG.ControlType.textLst:
                            case HG.ControlType.clockDial:
                            case HG.ControlType.historyCurves:
                            // 进度条控件
                            case HG.ControlType.progressBar:
                            // 图片数值对应控件
                            case HG.ControlType.pictureList:
                                // 设置
                                lParams = lCtrl.param;
                                // 添加参数
                                if (lParams) {
                                    // if (lCtrl.type!=HG.ControlType.historyCurves) {
                                    //     this.getDecimalFrom(lParams,lCtrl.decimalPoint,decimalFrom.decimalVos);
                                    // }
                                    // 添加参数
                                  
                                    this.addCOMMParams(lPg.idx, lCtrl.id, lParams, lPg.ctrls[vIdxCtrl].interfaceId,lCtrl.decimalPoint);
                                }
                                break;
                            case HG.ControlType.warningTable:
                                this.addCOMMParams(lPg.idx, lCtrl.id, "all", lPg.ctrls[vIdxCtrl].interfaceId);
                                break;
                            case HG.ControlType.curves:
                                // 获取数据
                                lDatas = lCtrl.curvesData;
                                break;
                            case HG.ControlType.columns:
                                // 获取数据
                                lDatas = lCtrl.columnsData;
                                break;
                            case HG.ControlType.pies:
                                // 获取数据
                                lDatas = lCtrl.piesData;
                                break;
                            case HG.ControlType.dataTable:
                                // 获取数据
                                for (let i = 0; i < lCtrl.dataTable.length; i++) {
                                    // let ctrl=lCtrl.dataTable[i]
                                    // if (ctrl.typeValue=="true") {
                                    //     this.getDecimalFrom(ctrl.param,ctrl.decimalPoint,decimalFrom.decimalVos);
                                    // }
                                    this.addCOMMParams(lPg.idx, lCtrl.id, lCtrl.dataTable[i].param, lPg.ctrls[vIdxCtrl].interfaceId);
                                    
                                }
                                break;
                        }
                        // 检查是否为空
                        if (lDatas) {
                            // 获取其中的参数
                            for (let vIdxParam = 0; vIdxParam < lDatas.length; vIdxParam++) {
                                // 清空
                                lParams = null;
                                // 获取参数
                                lParams = lDatas[vIdxParam].param;
                                // 检查是否有效
                                if (lParams) {
                                    // 添加参数
                                    this.addCOMMParams(lPg.idx, lCtrl.id, lParams, lPg.ctrls[vIdxCtrl].interfaceId);
                                }
                            }
                        }
                    }
                }

            }
        }
        // 检查是否存在
        if (vHMIComm.devices.length > 0) {
            // 通讯数据
            lCOMMData = JSON.stringify(vHMIComm);
            // 通讯保存
            HMIInterface.savePrjCOMM(HMIInterface.planID, lCOMMData);
        }
    },
    //-----------------------------------------------------------------------------
    // 添加通讯参数
    //
    addCOMMParams: function (vPageId, vCtrlId, vParams, vUrl,decimalPoint) {
        
        let bMultiple = false;
        // 检查参数
        if (vParams.length > 0) {
            // 检查详细的内容
            if (vParams[0].length) {
                // 检查数量
                if (vParams[0].length === 3) {
                    bMultiple = true;
                } 
            }
            // 检查是否为多重参数
            if (bMultiple) {
                // 添加每一个参数
                for (let vIdx = 0; vIdx < vParams.length; vIdx++) {
                    // 添加参数
                    if(vParams[vIdx].length > 1) {
                        // 检查是否为内部参数
                        if (vParams[vIdx][0] == HMIPlanData.PlanDataInsideParamKeys.insideParamValue) {
                            // 直接返回, 内部参数不使用
                            return;
                        }
                    }
                }
                this.addCOMMParamsDetail(vPageId, vCtrlId, null, null, vParams, vUrl,decimalPoint);
            }
            else {
                // 添加详细的内容
                if (vParams.length > 0) {
                    // 检查是否为内部参数
                    if (vParams[0] != HMIPlanData.PlanDataInsideParamKeys.insideParamValue) {
                        // 添加
                        this.addCOMMParamsDetail(vPageId, vCtrlId, vParams[0], vParams[1], vParams[2], vUrl);
                    }
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 添加通讯参数详细
    //
    addCOMMParamsDetail: function (pageId, ctrlId, gatewayId, subOriginalId, paramId, urlFunc) {
        // 添加通讯内容
        vHMIComm.comms.push({
            pageId: pageId,
            ctrlId: ctrlId,
            gatewayId: gatewayId,
            subOriginalId: subOriginalId,
            paramId: paramId,
            url: urlFunc,
            // decimalPoint:decimalPoint,
        });
        // 添加网关
        this.addCOMMSubGetway(gatewayId);
    },
    //-----------------------------------------------------------------------------
    // 添加网关
    //
    addCOMMSubGetway: function (gatewayId) {
        let lExist = false;
        // 搜索是否添加了subgetway
        for (let vDevIdx = 0; vDevIdx < vHMIComm.devices.length; vDevIdx++) {
            // 检查是否一致
            if (vHMIComm.devices[vDevIdx].gatewayId === gatewayId) {
                // 已经存在
                lExist = true;
                // 跳出
                break;
            }
        }
        // 检查是否存在
        if (!lExist) {
            // 添加通讯内容
            vHMIComm.devices.push({
                gatewayId: gatewayId,
                dtuSn: '',
            });
        }
    },

    setNewCtrls(data,resolve){
        console.log(JSON.stringify(data)!="{}");
      if(JSON.stringify(data)!="{}"){

  
        for (let i = 0; i < vHMIPrj.allPg.length; i++) {
        let vPage = vHMIPrj.allPg[i];
        for (var idx = 0; idx < vPage.ctrls.length; idx++) {
            let vCtrlData = vPage.ctrls[idx];
            // 检查控件类型
            switch (vCtrlData.type) {

                // 指示灯控件
                case HG.ControlType.light:
                // 位按钮
                case HG.ControlType.bbtn:
                // 字按钮
                case HG.ControlType.wbtn:
                // 数值输入控件
                case HG.ControlType.num:
                // 文数对应
                case HG.ControlType.textLst:

                case HG.ControlType.clockDial:
                // 进度条控件
                case HG.ControlType.progressBar:
                // 图片数值对应控件
                case HG.ControlType.pictureList:
                
                // 进度条属性 上传专用
                case HG.ControlType.progressBar:
               // 图片数值对应控件 上传专用
                case HG.ControlType.pictureList:
                    //    vCtrlData.param
                    if (vCtrlData.param.length!=3) {
                        break;
                    }
                    vCtrlData.param[0]=data.gatewayIds[`${vCtrlData.param[0]}`]?data.gatewayIds[`${vCtrlData.param[0]}`]:vCtrlData.param[0];
                    vCtrlData.param[1]=data.subOriginalIds[`${vCtrlData.param[1]}`]?data.subOriginalIds[`${vCtrlData.param[1]}`]:vCtrlData.param[1];
                    vCtrlData.param[2]=data.paramIds[`${vCtrlData.param[2]}`]?data.paramIds[`${vCtrlData.param[2]}`]:vCtrlData.param[2];
                    break;
                // 数据表格  
                case HG.ControlType.dataTable:
                    let item
                    let dataList = vCtrlData.dataTable;
                    for (let e = 0; e < dataList.length; e++) {
                        item=dataList[e];
                        if (item.param.length!=3) {
                            break;
                        }
                        item.param[0]=data.gatewayIds[`${item.param[0]}`]?data.gatewayIds[`${item.param[0]}`]:item.param[0];
                        item.param[1]=data.subOriginalIds[`${item.param[1]}`]?data.subOriginalIds[`${item.param[1]}`]:item.param[1];
                        item.param[2]=data.paramIds[`${item.param[2]}`]?data.paramIds[`${item.param[2]}`]:item.param[2];
                    }

                    break;
                case HG.ControlType.historyCurves:
                    for (let i = 0; i < vCtrlData.param.length; i++) {
                        if (vCtrlData.param.length!=3) {
                            break;
                        }
                        vCtrlData.param[i][0]=data.gatewayIds[`${vCtrlData.param[i][0]}`]?data.gatewayIds[`${vCtrlData.param[i][0]}}`]:vCtrlData.param[i][0];
                        vCtrlData.param[i][1]=data.subOriginalIds[`${vCtrlData.param[i][1]}`]?data.subOriginalIds[`${vCtrlData.param[i][1]}}`]:vCtrlData.param[i][1];
                        vCtrlData.param[i][2]=data.paramIds[`${vCtrlData.param[i][2]}`]?data.paramIds[`${vCtrlData.param[i][2]}}`]:vCtrlData.param[i][2];
                    }

                    break;
           
                    default:
                        break;
                }

        }
    }
}
        HMIInterface.planID=data[HMIInterface.planID]
     
            // 通讯保存, 并处理临时HTML DOM控件
        this.saveProjectCOMM();
            // 项目数据转换为字符格式
        let lPrjData = JSON.stringify(vHMIPrj);
            // 项目保存
        HMIInterface.saveProject(HMIInterface.planID, lPrjData);
        resolve();
    },


    //-----------------------------------------------------------------------------
    // 保存项目
    //
    saveProject: function () {
        // 项目数据
        let lPrjData = null;

        // 项目的编辑状态重置
        vHMIPrj.modified = false;

        // 通讯保存, 并处理临时HTML DOM控件
        this.saveProjectCOMM();

        // 项目数据转换为字符格式
        lPrjData = JSON.stringify(vHMIPrj);
        // 项目保存
        HMIInterface.saveProject(HMIInterface.planID, lPrjData);
    },
    //-----------------------------------------------------------------------------
    // 读取项目
    //
    loadProject: function (vPrjData) {
        
        // 项目读取
        var vPrj = vPrjData;
        // 检查是否有效
        if (vPrj) {
            // 读取项目后检查数据
            this.loadProjectCheckData(vPrj);
            // 项目属性
            vHMIPrj.setting = JSON.parse(JSON.stringify(vPrj.setting));
            // 当前项目中选中的页面
            vHMIPrj.curPg = vPrj.curPg;
            //-----------------------------------------------------------------------
            // LEE 2022/04/25 启动画面
            //
            vHMIPrj.startPg = vPrj.startPg;
            // 项目页面详细
            vHMIPrj.allPg = JSON.parse(JSON.stringify(vPrj.allPg));
            vHMIPrj.allPg.forEach(item=>{
                item.ctrls.forEach(element=>{
            element.x=element.x*HMIDraw.settings.domZoom;
            element.y=element.y*HMIDraw.settings.domZoom;
            element.w=element.w*HMIDraw.settings.domZoom;
            element.h=element.h*HMIDraw.settings.domZoom;
            element.fontSize=element.fontSize*HMIDraw.settings.domZoom;
            if (element.scaleSize) {
                element.scaleSize=element.scaleSize*HMIDraw.settings.domZoom;
            }
            // gInterface.openWs();
                })
            })
        
            // 项目是否改变
            vHMIPrj.modified = vPrj.modified;
            // 绘制是否需要刷新
            vHMIPrj.refreshDraw = vPrj.refreshDraw;
            // 所有的图片地址
            vHMIPrj.allImgs = JSON.parse(JSON.stringify(vPrj.allImgs));

            // 系统权限
            if (vPrj.sysPermissions) {
                // 设置
                for (let lIdx = 0; lIdx < vPrj.sysPermissions.length; lIdx++) {
                    // 最大值限制
                    if(lIdx < vHMIPrj.sysPermissions.length) {
                        // 设置系统密码
                        vHMIPrj.sysPermissions[lIdx] = vPrj.sysPermissions[lIdx];
                    }
                }
            }
            // 当前登录权限
            vHMIPrj.curLoginPermission = 0;

            // 添加项目中的图片到所有图片列表
            this.addProjectImages();
        }
    },
    //-----------------------------------------------------------------------------
    // 读取项目后检查数据
    //
    loadProjectCheckData: function (vPrj) {
        // 获取页面
        let vPage = null;
        // 检查文件版本
        if(!vPrj.version) {
            // 默认版本
            vPrj.version = 0.9;
        }
        // 检查版本
        if(vPrj.version < PRJ_FILE_VERSION) {
            // 设置版本
            vPrj.version = PRJ_FILE_VERSION;
            //
            // 重置各个页面的ID
            //
            
            for (let idPage = 0; idPage < vPrj.allPg.length; idPage++) {
                // 获取页面
                vPage = vPrj.allPg[idPage];
                // 各个控件
                for (let idx = 0; idx < vPage.ctrls.length; idx++) {
                    // 获取控件
                    vPage.ctrls[idx].id = idx + 1;
                }
                // 控件最大ID
                vPage.maxCtrlID = vPage.ctrls.length + 1;
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 添加项目中的图片到所有图片列表
    //
    addProjectImages: function () {
        // 索引
        let idx = 0, idxNew = 0;
        let name = '', url = '';

        // 检查是否有效
        if (vHMIPrj.allImgs) {
            // 清空全局图片管理
            for (idx = HG.$Set.allImgsData.length - 1; idx >= 0; idx--) {
                // 检查是否为静态图片
                if (HG.$Set.allImgsData[idx].static) {
                    // 检查是静态图片时，退出
                    break;
                }
                // 添加到全局图片管理中
                HG.$Set.allImgsData.pop();
            }
            // 遍历所有的图片，并添加到系统图片列表中
            for (idx = 0; idx < vHMIPrj.allImgs.length; idx++) {
                // 图片名称、Url
                name = vHMIPrj.allImgs[idx].name;
                url = vHMIPrj.allImgs[idx].url;
                // 新的索引
                idxNew = HG.$Set.allImgsData.length;
                // 添加到全局图片管理中
                HG.$Set.allImgsData.push({ idx: idxNew, src: name, url: url, img: null });
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 项目改变
    //
    setProjectModified: function () {
        // 设置项目是否改变
        vHMIPrj.modified = true;
        // 绘制是否需要刷新
        vHMIPrj.refreshDraw = true;
    },
    //-----------------------------------------------------------------------------
    // 检查是否能够可以粘贴
    //
    canPasteCtrls: function () {
        let vCookiesData = null;
        // 是否已经复制控件
        if (HG.$Set.allCopyData.count > 0) return true;

        // 检查复制粘贴控件数量
        if(0 == HG.$Set.allCopyData.count) {
            // 加载粘贴控件数量
            vCookiesData = HG.$Set.allSettings.pasteControlsCnt();
            // 检查数据是否有效
            if ((vCookiesData != null) && (vCookiesData != '')) {
                // 转换数量
                if(parseInt(vCookiesData) > 0) return true;
            }
        }

        return false;
    },
    //-----------------------------------------------------------------------------
    // 是否能够执行操作
    //
    canExecOperate: function (opt) {
        // 检查操作类型
        switch (opt) {
            // 撤销
            case HG.OperateType.undo:
                // 检查页面是否可以撤销
                return HMIOperateCtrlMG.checkPageCanUndo(vHMIPrj.curPg);
            // 重做
            case HG.OperateType.redo:
                // 检查页面是否可以重做
                return HMIOperateCtrlMG.checkPageCanRedo(vHMIPrj.curPg);
            // 剪切
            case HG.OperateType.cut:
                // 当前页面是否有选中的控件
                return (this.getSelectedCtrlsCnt() > 0);
            // 复制
            case HG.OperateType.copy:
                // 当前页面是否有选中的控件
                return (this.getSelectedCtrlsCnt() > 0);
            // 粘贴
            case HG.OperateType.paste:
                // 是否已经复制控件
                return this.canPasteCtrls();
            // 删除
            case HG.OperateType.delete:
                // 当前页面是否有选中的控件
                return (this.getSelectedCtrlsCnt() > 0);
            // 保存
            case HG.OperateType.save:
                return true;
            // 预览
            case HG.OperateType.view:
                return true;
        }

        // 默认不可用
        return false;
    },
    //-----------------------------------------------------------------------------
    // 执行操作
    //
    execOperate: function (opt) {
        // 检查操作类型
        switch (opt) {
            // 撤销
            case HG.OperateType.undo:
                // 页面执行撤销
                HMIOperateCtrlMG.exeUndo(vHMIPrj.curPg);
                break;
            // 重做
            case HG.OperateType.redo:
                // 页面执行重做
                HMIOperateCtrlMG.exeRedo(vHMIPrj.curPg);
                break;
            // 剪切
            case HG.OperateType.cut:
                // 剪切
                this.cutAllSelectedCtrls();
                break;
            // 复制
            case HG.OperateType.copy:
                // 复制
                this.copyAllSelectedCtrls();
                break;
            // 粘贴
            case HG.OperateType.paste:
                // 粘贴
                this.pasteCtrls();
                break;
            // 删除
            case HG.OperateType.delete:
                // 删除选中的控件
                this.deleteAllSelectedCtrls();
                break;
            // 保存
            case HG.OperateType.save:
                // 保存项目
                this.saveProject();
                break;
            // 预览
            case HG.OperateType.view:
                // 跳转到预览画面，这里不执行任何操作
                break;
        }
    },

    //-----------------------------------------------------------------------------
    // 在运行模式下执行点击操作
    //
    checkOnExecableCtrl: function (x, y) {
        // 控件内容
        var vCtrl = null;
        var vExecableCtrl = null;
        // 索引
        var idx = 0;
        // 检查是否点击到下拉列表关联控件
        if (this.execPointDropDownListValid(x, y)) {
            return true;
        }
        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 区域相交
            if ((x >= vCtrl.x) && (x <= (vCtrl.x + vCtrl.w)) && (y >= vCtrl.y) && (y <= (vCtrl.y + vCtrl.h))) {
                // 检查是否可执行控件
                vExecableCtrl = vCtrl;
                break;
            }
        }
        // 执行控件
        if (null != vExecableCtrl) {
            // 检查控件类型
            switch (vExecableCtrl.type) {
                case HG.ControlType.bbtn:
                case HG.ControlType.wbtn:
                case HG.ControlType.pbtn:
                case HG.ControlType.num:
                    return true;
            }
        }

        // 不在可执行控件上
        return false;
    },
    //-----------------------------------------------------------------------------
    // 在运行模式下执行点击操作
    //
    execPointOperation: function (x, y) {
        // 控件内容
        var vCtrl = null;
        var vSelectedCtrl = null;
        // 索引
        var idx = 0;

        // 检查是否点击到下拉列表关联控件
        if (this.execPointDropDownListValid(x, y)) {
            // 在运行模式下点击下拉列表
            this.execPointDropDownList(x, y);
            return;
        }
        // 清除下拉列表关联控件
        vHMIPrj.curDdlLinked.ctrl = null;

        // 检查控件
        for (idx = vHMIPrj.allPg[vHMIPrj.curPg].ctrls.length - 1; idx >= 0; idx--) {
            // 获取控件
            vCtrl = vHMIPrj.allPg[vHMIPrj.curPg].ctrls[idx];
            // 区域相交
            if ((x >= vCtrl.x) && (x <= (vCtrl.x + vCtrl.w)) && (y >= vCtrl.y) && (y <= (vCtrl.y + vCtrl.h))) {
                // 执行点击操作的控件
                vSelectedCtrl = vCtrl;
                break;
            }
        }
        // 执行点击操作的控件
        if (null != vSelectedCtrl) {
            // 检查是否为只读控件
            if (!vCtrl.readonly) {
                // 检查操作权限
                if(vSelectedCtrl.optPermission) {
                    // 检查当前登录权限
                    if (vHMIPrj.curLoginPermission < vSelectedCtrl.optPermission) {
                        // 系统密码输入外部句柄
                        if (this.systemPermissionHandler) {
                            // 调用外部执行句柄
                            this.systemPermissionHandler(vCtrl);
                        }
                        // 返回
                        return;
                    }
                }
            }
            // 检查控件类型
            switch (vSelectedCtrl.type) {
                case HG.ControlType.bbtn:
                    // 执行控件位按钮操作
                    this.execOPTBBtn(vSelectedCtrl);
                    break;
                case HG.ControlType.wbtn:
                    // 执行控件字按钮操作
                    this.execOPTWBtn(vSelectedCtrl);
                    break;
                case HG.ControlType.pbtn:
                    // 执行控件页面按钮操作
                    this.execOPTPBtn(vSelectedCtrl);
                    break;
                case HG.ControlType.num:
                    // 执行输入控件
                    this.execOPTNum(vSelectedCtrl);
                    break;
                case HG.ControlType.textLst:
                    // 检查是否为下拉列表
                    if (vSelectedCtrl.editable) {
                        // 检查控件是否一致
                        if (vSelectedCtrl != vHMIPrj.curDdlLinked.ctrl) {
                            // 设置 下拉列表关联控件
                            vHMIPrj.curDdlLinked.ctrl   = vSelectedCtrl;
                            // 重置属性
                            vHMIPrj.curDdlLinked.x      = 0;
                            vHMIPrj.curDdlLinked.y      = 0;
                            vHMIPrj.curDdlLinked.w      = 0;
                            vHMIPrj.curDdlLinked.h      = 0;
                            vHMIPrj.curDdlLinked.start  = 0;
                            vHMIPrj.curDdlLinked.max    = 0;
                        }
                    }
                    break;
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 在运行模式下是否点击到下拉列表
    //
    execPointDropDownListValid: function (x, y) {
        // 检查是否在下拉列表显示框中
        if (null != vHMIPrj.curDdlLinked.ctrl) {
            // 检查是否在下拉列表显示框中
            if ((vHMIPrj.curDdlLinked.w > 0) && (vHMIPrj.curDdlLinked.h > 0)) {
                // 检查是否处于区域
                if ((x >= vHMIPrj.curDdlLinked.x) && 
                    (x <= (vHMIPrj.curDdlLinked.x + vHMIPrj.curDdlLinked.w)) && 
                    (y >= vHMIPrj.curDdlLinked.y) && 
                    (y <= (vHMIPrj.curDdlLinked.y + vHMIPrj.curDdlLinked.h))) {
                    // 计算点击下拉列表位置
                    // 有效位置
                    return true;
                }
            }
        }
        return false;
    },
    //-----------------------------------------------------------------------------
    // 在运行模式下点击下拉列表
    //
    execPointDropDownList: function (x, y) {
        let vXPos           = 0, vYPos = 0;
        let vDisplayScroll  = false;
        // 下拉列表滚动条宽度
        let vDisplayScrollW = HG.$Set.allSettings.scrollSize.ddlScrollBarWidth;
        // 下拉列表滚动条索引
        let vDisplayItemIdx = 0;
        // 检查是否在下拉列表显示框中
        if (null != vHMIPrj.curDdlLinked.ctrl) {
            // 检查是否在下拉列表显示框中
            if ((vHMIPrj.curDdlLinked.w > 0) && (vHMIPrj.curDdlLinked.h > 0)) {
                // 检查是否处于区域
                if ((x >= vHMIPrj.curDdlLinked.x) && 
                    (x <= (vHMIPrj.curDdlLinked.x + vHMIPrj.curDdlLinked.w)) && 
                    (y >= vHMIPrj.curDdlLinked.y) && 
                    (y <= (vHMIPrj.curDdlLinked.y + vHMIPrj.curDdlLinked.h))) {
                    // 检查是否显示滚动条
                    if (vHMIPrj.curDdlLinked.ctrl.listValue.length > vHMIPrj.curDdlLinked.max) {
                        // 存在滚动条
                        vDisplayScroll = true;
                    } 
                    // 计算点击下拉列表位置
                    vXPos = x - vHMIPrj.curDdlLinked.x;
                    vYPos = y - vHMIPrj.curDdlLinked.y;
                    // 存在滚轮条时是否点击到滚动区域
                    if (vDisplayScroll) {
                        // 检查区域
                        if (vXPos >= vHMIPrj.curDdlLinked.w - vDisplayScrollW) {
                            // 点击到滚动区域
                            if (vYPos < (vHMIPrj.curDdlLinked.h / 2)) {
                                // 向上
                                if (vHMIPrj.curDdlLinked.start > 0) {
                                    // 递减
                                    vHMIPrj.curDdlLinked.start--;
                                }
                            } else {
                                // 向下
                                if ((vHMIPrj.curDdlLinked.start + vHMIPrj.curDdlLinked.max) < vHMIPrj.curDdlLinked.ctrl.listValue.length) {
                                    // 递加
                                    vHMIPrj.curDdlLinked.start++;
                                }
                            }
                            return;
                        }
                    }
                    // 点击项目的索引
                    vDisplayItemIdx = parseInt(vYPos / vHMIPrj.curDdlLinked.ctrl.h);
                    vDisplayItemIdx += vHMIPrj.curDdlLinked.start;
                    // 设置默认数据值
                    let _d = {
                        projectId: $store.state.projectId,
                        gatewayId: vHMIPrj.curDdlLinked.ctrl.param[0],
                        subOriginalId: vHMIPrj.curDdlLinked.ctrl.param[1],
                        paramId: vHMIPrj.curDdlLinked.ctrl.param[2],
                        value: Number(vDisplayItemIdx),
                        page:HMIPrj.vHMIPrj.curPg*1+1
                    };
                    // 清除当前的下拉列表相关属性
                    vHMIPrj.curDdlLinked.ctrl      = null;
                    vHMIPrj.curDdlLinked.x         = 0;
                    vHMIPrj.curDdlLinked.y         = 0;
                    vHMIPrj.curDdlLinked.w         = 0;
                    vHMIPrj.curDdlLinked.h         = 0;
                    vHMIPrj.curDdlLinked.start     = 0;
                    vHMIPrj.curDdlLinked.max       = 0;
                    // 执行数据改变
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: '下发中，请稍后...',
                        background: 'rgba(255, 255, 255, 0.5)',
                        target:document.querySelector('.el-dialog__body')
                    });
                    $api.project.sendData(_d).then(response => {
                        // 检查执行code
                        loadingInstance.close()
                        if (response.code == 200) {
                           
                            // 下发完成
                            Message({
                                type: "success",
                                message: "动作已提交，请等待数据更新，勿重复点击！",
                            });
                        }
                    }).catch(() => {
                        alert("下发失败，请重试！")
                    })
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 执行控件位按钮操作
    //
    execOPTBBtn: function (vCtrl) {
        let vData = 0;
        // 检查是否为位按钮
        if (HG.ControlType.bbtn != vCtrl.type) {
            return;
        }
        // 检查数据值是否存在
        if (!vCtrl.dataValue) {
            // 设置默认数据值
            vCtrl.dataValue = 0;
        }
        // 获取数据
        vData = parseInt(vCtrl.dataValue);
        // 检查运行的模式
        switch (vCtrl.mode) {
            case HG.AttrSelType.BbtnValueSetType.setON:
                // 设定为ON
                vData = 1;
                break;
            case HG.AttrSelType.BbtnValueSetType.setOFF:
                // 设定为OFF
                vData = 0;
                break;
            case HG.AttrSelType.BbtnValueSetType.change:
                // 检查是否为1
                if (0 === vData) {
                    // 交替
                    vData = 1;
                } else {
                    // 交替
                    vData = 0;
                }
                break;
        }
        // 设置默认数据值
        let _d = {
            projectId: $store.state.projectId,
            gatewayId: vCtrl.param[0],
            subOriginalId: vCtrl.param[1],
            paramId: vCtrl.param[2],
            value: Number(vData),
            page:HMIPrj.vHMIPrj.curPg*1+1
        };
        let loadingInstance = Loading.service({
            lock: true,
            text: '下发中，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.5)',
            target:document.querySelector('.el-dialog__body')
        });
        $api.project.sendData(_d).then(response => {
            loadingInstance.close()
            if (response.code == 200) {
                // vCtrl.dataValue = vData;
                //
                // 执行数据操作
                //
                // HMIDataPool.setCOMMData(vCtrl.param, vData);
                // alert("动作以提交，请等待数据更新，勿重复点击！")
              
                Message({
                    type: "success",
                    message: "动作已提交，请等待数据更新，勿重复点击！",
                });
            } 
        }).catch(() => {
            alert("下发失败，请重试！")
        })
        
    },
    //-----------------------------------------------------------------------------
    // 执行控件字按钮操作
    //
    execOPTWBtn: function (vCtrl) {
        let vData = 0;
        // 检查是否为字按钮
        if (HG.ControlType.wbtn != vCtrl.type) {
            return;
        }
        // 检查数据值是否存在
        if (!vCtrl.dataValue) {
            // 设置默认数据值
            vCtrl.dataValue = 0;
        }
        // 获取数据
        vData = parseFloat(vCtrl.dataValue);
        // 检查字按钮的编辑模式
        switch (vCtrl.mode) {
            // 增加
            case HG.AttrSelType.WbtnValueSetType.add:
                // 添加
                vData += parseFloat(vCtrl.cmdValue);
                // 检查是否有效
                if (null != vCtrl.limitedValue) {
                    // 检查是否超出重置
                    if (vCtrl.resetOutRange) {
                        // 检查是否超出
                        if (vData > Number(vCtrl.limitedValue)) {
                            // 设置为重置值
                            vData = Number(vCtrl.resetValue);
                        }
                    } else {
                        // 检查是否超出
                        if (vData > Number(vCtrl.limitedValue)) {
                            // 设置为最大值
                            vData = Number(vCtrl.limitedValue);
                        }
                    }
                }
                break;
            // 减少
            case HG.AttrSelType.WbtnValueSetType.dec:
                // 减少
                vData -= parseFloat(vCtrl.cmdValue);
                // 检查是否有效
                if (null != vCtrl.limitedValue) {
                    // 检查是否超出重置
                    if (vCtrl.resetOutRange) {
                        // 检查是否超出
                        if (vData < Number(vCtrl.limitedValue)) {
                            // 设置为重置值
                            vData = Number(vCtrl.resetValue);
                        }
                    } else {
                        // 检查是否超出
                        if (vData < Number(vCtrl.limitedValue)) {
                            // 设置为最大值
                            vData = Number(vCtrl.limitedValue);
                        }
                    }
                }
                break;
            // 设常量
            case HG.AttrSelType.WbtnValueSetType.setConst:
                // 设常量
                vData = parseFloat(vCtrl.cmdValue);
                break;
        }
        // 设置默认数据值
        // 设置默认数据值
        let _d = {
            projectId: $store.state.projectId,
            gatewayId: vCtrl.param[0],
            subOriginalId: vCtrl.param[1],
            paramId: vCtrl.param[2],
            value: Number(vData),
            page:HMIPrj.vHMIPrj.curPg*1+1
        };
        let loadingInstance = Loading.service({
            lock: true,
            text: '下发中，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.5)',
            target:document.querySelector('.el-dialog__body')
        });
        $api.project.sendData(_d).then(response => {
            loadingInstance.close()
            if (response.code == 200) {
                vCtrl.dataValue = vData;
                //
                // 执行数据操作
                //
                // HMIDataPool.setCOMMData(vCtrl.param, vData);
                // alert("动作以提交，请等待数据更新，勿重复点击！")
              
                 Message({
                    type: "success",
                    message: "动作已提交，请等待数据更新，勿重复点击！",
                });
            } 
        }).catch(() => {
            alert("下发失败，请重试！")
        })
        // vCtrl.dataValue = vData;
        // //
        // // 执行数据操作
        // //
        // HMIDataPool.setCOMMData(vCtrl.param, vData);
    },
    //-----------------------------------------------------------------------------
    // 执行控件页面按钮操作
    //
    execOPTPBtn: function (vCtrl) {
        // 检查是否为页面按钮
        if (HG.ControlType.pbtn != vCtrl.type) {
            return;
        }

        // 检查跳转页面
        if (vCtrl.linkPg >= 0) {
            // 检查跳转页面
            if ((vCtrl.linkPg >= 0) && (vCtrl.linkPg < vHMIPrj.allPg.length)) {
                // 设置当前页面
                gInterface.delPage(vHMIPrj.curPg*1+1);
                vHMIPrj.curPg = vCtrl.linkPg;
                gInterface.openWs();
                HMIDataPool.loadAllInerfaceData(vHMIPrj.curPg);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 执行输入控件
    //
    execOPTNum: function (vCtrl) {
        // 检查是否为输入控件
        if (HG.ControlType.num != vCtrl.type) {
            return;
        }
        // 检查是否为只读控件
        if (false == vCtrl.readonly) {
            // 输入控件外部句柄
            if (this.inputControlHandler) {
                // 调用外部
                this.inputControlHandler(vCtrl);
            }
        }
    },

    //-----------------------------------------------------------------------------
    // 刷新页面的控件数据
    //
    refreshPageCtrlsData: function (vPage, vForceRefresh) {
        // 控件内容
        var vCtrl = null;
        var vItem = null;
        // 索引
        var idx = 0;
        var idxSub = 0;
        // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
        var vDataMode = 0;
        var vDataArray = null;

        // 检查数据是否刷新或者强制刷新
        if (vForceRefresh || HMIDataPool.modified) {
            // 检查控件
            for (idx = 0; idx < vPage.ctrls.length; idx++) {
                // 获取控件
                
                vCtrl = vPage.ctrls[idx];
                // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
                vDataMode = 0;
                // 检查控件类型
                switch (vCtrl.type) {
                    case HG.ControlType.light:
                    case HG.ControlType.bbtn:
                    case HG.ControlType.wbtn:
                    case HG.ControlType.num:
                    case HG.ControlType.clockDial:
                    case HG.ControlType.textLst:
                    // 进度条控件
                    case HG.ControlType.progressBar:
                    // 图片数值对应控件
                    case HG.ControlType.pictureList:
                        // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
                        vDataMode = 1;
                        break;
                    case HG.ControlType.columns:
                        // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
                        vDataMode = 2;
                        // 柱状数据
                        vDataArray = vCtrl.columnsData;
                        break;
                    case HG.ControlType.pies:
                        // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
                        vDataMode = 2;
                        // 饼图数据
                        vDataArray = vCtrl.piesData;
                        break;
                    case HG.ControlType.dataTable:
                        // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置;3:数据表格格式)
                        vDataMode = 3;
                        break;
                    default:
                        // 暂时不处理
                        break;
                }
                // 数据模式(0:没有数据; 1:简单数据设置; 2:复杂数据设置)
                if (1 == vDataMode) {
                    // 设置  
                    vCtrl.dataValue = HMIDataPool.loadCOMMData(vCtrl.param, vCtrl.dataValue);
                }
                // 复杂数据设置 
                else if (2 == vDataMode) {
                    // 检查柱状图数据是否存在
                    if (vDataArray) {
                        // 绘制柱状图
                        for (idxSub = 0; idxSub < vDataArray.length; idxSub++) {
                            // 获取柱状数据
                            vItem = vDataArray[idxSub];
                            // 编辑模式下绘制示例曲线
                            if (vItem) {
                                // 柱状数据
                                vItem.dataValue = HMIDataPool.loadCOMMData(vItem.param, 0);
                            }
                        }
                    }
                }else if (3 == vDataMode) {
                    
                    for (let i = 0; i < vCtrl.dataTable.length; i++) {
                        let values=HMIDataPool.loadTableData(vCtrl.dataTable[i].param, vCtrl.dataTable[i].value)
                        if (values!=vCtrl.dataTable[i].value) {
                            HMIDraw.valueNew=true;
                        }

                        vCtrl.dataTable[i].value=values
                       
                    }
                    
                    // let arr=[];
                    // vCtrl.dataTable.forEach(element => {
                    //     arr.push(HMIDataPool.loadCOMMData(element.dataValue, vCtrl.dataValue));
                    // });
                    // vCtrl.dataValue=arr;

                }
            }

            // 重置页面改变标志
            HMIDataPool.modified = false;
        }
    },

    //-----------------------------------------------------------------------------
    // 设置内部控件的数据
    //
    setCtrlsInsideData: function (vParam, vData) {
        // 遍历页面
        vHMIPrj.allPg.forEach((page) => {
            // 遍历控件
            page.ctrls.forEach((control) => {
                // 检查控件是否有效
                if (control) {
                    // 检查控件参数是否存在
                    if (control.param) {
                        // 参数是否一致
                        if (control.param.length && vParam.length) {
                            // 检查长度
                            if ((control.param.length == vParam.length) && (control.param.length >= 3)) {
                                // 检查长度
                                if ((control.param[0] == vParam[0]) &&
                                    (control.param[1] == vParam[1]) &&
                                    (control.param[2] == vParam[2])) {
                                    // 设置数据值
                                    control.dataValue = vData;
                                }
                            }
                        }
                    }
                }
            });
        });
    }
};

// 外部接口暴露
export default {
    // 获取当前项目
    vHMIPrj,
    // 获取当前项目处理函数
    vHMIPrjFunc,
}
