export const routes = [
    {
        path: '/Documentation',
        component: resolve => {
            require(['../view/Documentation'], resolve)
        },
        name: 'Documentation'
    },
    {
        path: '/iot-:path',
        component: resolve => {
            require(['../view/Login'], resolve)
        },
        name: 'login'
    },
    {
        path: "/main",
        component: resolve => {
            require(['../view/MainPage'], resolve)
        },
        children: [
            {
                path: '/map',
                component: resolve => {
                    require(['../view/map'], resolve)
                },
                name: 'Map',
            },
            {
            path: '/home',
            component: resolve => {
                require(['../view/Home'], resolve)
            },
            name: 'home',
        },
            {
            path: '/projectInformation',
            component: resolve => {
                require(['../view/projectInformation'], resolve)
            },
            name: 'projectInformation',
        },
         {
            path: '/system',
            component: resolve => {
                require(['../view/System'], resolve)
            },
            name: 'System',
            children: [{
                path: '/system/baseInfo',
                component: resolve => {
                    require(['../view/BaseInfo'], resolve)
                },
                name: 'BaseInfo',
            },
               // 状态管理S
        {
            path: '/system/projectTempState',
            component: resolve => {
                require(['../view/projectTempState'], resolve)
            },
            name: 'ProjectTempState',
            }, 
        // 状态管理E
             {
                path: '/system/menuManagement',
                component: resolve => {
                    require(['../view/MenuManagement'], resolve)
                },
                name: 'menuManagement',
                }, 
                {
                    path: "/system/downLoadPage",
                    component: () => import("../view/downLoadPage"),
                    name: "downLoadPage",
                  },
             {
                path: '/system/WorkOrder',
                component: resolve => {
                    require(['../view/WorkOrder'], resolve)
                },
                name: 'WorkOrder',
            }, 
            {
                path: '/system/role',
                component: resolve => {
                    require(['../view/Role'], resolve)
                },
                name: 'Role',
            }, {
                path: '/system/account',
                component: resolve => {
                    require(['../view/Account'], resolve)
                },
                name: 'Account',
            }, {
                path: '/system/industry',
                component: resolve => {
                    require(['../view/Industry'], resolve)
                },
                name: 'Industry',
            }, {
                path: '/system/communicationProtocol',
                component: resolve => {
                    require(['../view/CommunicationProtocol'], resolve)
                },
                name: 'CommunicationProtocol',
            }, {
                path: '/system/contacts',
                component: resolve => {
                    require(['../view/Contacts'], resolve)
                },
                name: 'Contacts',
            }, {
                path: '/system/networkMode',
                component: resolve => {
                    require(['../view/NetworkMode'], resolve)
                },
                name: 'NetworkMode',
            }, {
                path: '/system/dtu',
                component: resolve => {
                    require(['../view/Dtu'], resolve)
                },
                name: 'Dtu',
            },
            {
                path: '/system/operationLog',
                component: resolve => {
                    require(['../view/OperationLog'], resolve)
                },
                name: 'OperationLog',
            },
            {
                path: '/system/Interface',
                component: resolve => {
                    require(['../view/Interface'], resolve)
                },
                name: 'Interface',
            },
            {
                path: '/system/Attribute',
                component: resolve => {
                    require(['../view/Attribute'], resolve)
                },
                name: 'Attribute',
            },
            
             {
                path: '/system/mqtt',
                component: resolve => {
                    require(['../view/Mqtt'], resolve)
                },
                name: 'Mqtt',
            },
            {
                path: '/system/dataCondition',
                component: resolve => {
                    require(['../view/dataCondition'], resolve)
                },
                name: 'DataCondition',
            },
            {
                path: '/system/baseCondition',
                component: resolve => {
                    require(['../view/baseCondition'], resolve)
                },
                name: 'BaseCondition',
            },
            {
                path: '/system/configurationImgType',
                component: resolve => {
                    require(['../view/ConfigurationImgType'], resolve)
                },
                name: 'ConfigurationImgType',
            },
            {
                path: '/system/dtuBindingLog',
                component: resolve => {
                    require(['../view/dtuBindingLog'], resolve)
                },
                name: 'DtuBindingLog',
            },
            {
                path: '/system/dtuOnlineLog',
                component: resolve => {
                    require(['../view/dtuOnlineLog'], resolve)
                },
                name: 'dtuOnlineLog',
            },
            {
                path: '/system/ThirdPartyDtu',
                component: resolve => {
                    require(['../view/ThirdPartyDtu'], resolve)
                },
                name: 'ThirdPartyDtu',
            },
           
        ]
        }, 
        {
            path: '/analysisData',
            component: resolve => {
                require(['../view/AnalysisData'], resolve)
            },
            name: 'AnalysisData',
            children:[
                {
                    path: '/analysisData/history',
                    component: resolve => {
                        require(['../view/History'], resolve)
                    },
                    name: 'History',
                },
                {
                    path: '/analysisData/application',
                    component: resolve => {
                        require(['../view/Application'], resolve)
                    },
                    name: 'Application',
                },
            ]
        }, 
        
        // {
        //     path: '/video',
        //     component: resolve => {
        //         require(['../view/Video'], resolve)
        //     },
        //     name: 'Video',
        //     children:[
               
        //         {
        //             path: '/video/ysSystem',
        //             component: resolve => {
        //                 require(['../view/ysSystem'], resolve)
        //             },
        //             name: 'YsSystem',
        //         },
        //     ]
        // },
        
        {
            path: '/desgin',
            component: resolve => {
                require(['../view/Desgin'], resolve)
            },
            name: 'Desgin',
            children: [{
                path: '/desgin/originalScript',
                component: resolve => {
                    require(['../view/OriginalScript'], resolve)
                },
                name: 'OriginalScript',
            }, {
                path: '/desgin/gateway',
                component: resolve => {
                    require(['../view/Gateway'], resolve)
                },
                name: 'Gateway',
            }, {
                path: '/desgin/plan',
                component: resolve => {
                    require(['../view/Plan'], resolve)
                },
                name: 'Plan',
            }, {
                path: '/desgin/solution',
                component: resolve => {
                    require(['../view/Solution'], resolve)
                },
                name: 'Solution',
            }, {
                path: '/desgin/operate',
                component: resolve => {
                    require(['../view/Operate'], resolve)
                },
                name: 'Operate',
            }]
            },
            {
            path: '/projectGroup',
            component: resolve => {
                require(['../view/ProjectGroup'], resolve)
            },
            name: 'ProjectGroup',
            }, 
        {
            path: '/warning',
            component: resolve => {
                require(['../view/Warning'], resolve)
            },
            name: 'Warning',
        }, {
            path: '/ueditor',
            component: resolve => {
                require(['../view/EditorDoc'], resolve)
            },
        }, {
            path: '/extendedService',
            component: resolve => {
                require(['../view/ExtendedService'], resolve)
            },
            name: 'extendedService',
            children: [
                {
                    path: '/extendedService/functionCenter',
                    component: resolve => {
                        require(['../view/functionCenter'], resolve)
                    },
                    name: 'extendedServices',
                },
                {
                    path: '/extendedService/platform',
                    component: resolve => {
                        require(['../view/platform'], resolve)
                    },
                    name: 'Platform',
                },
                {
                    path: '/extendedService/Weekly',
                    component: resolve => {
                        require(['../view/Weekly'], resolve)
                    },
                    name: 'Weekly',
                },
                {
                    path: '/extendedService/camera',
                    component: resolve => {
                        require(['../view/camera'], resolve)
                    },
                    name: 'Camera',
                },
                {
                    path: '/extendedService/Iccid',
                    component: resolve => {
                        require(['../view/Iccid'], resolve)
                    },
                    name: 'Iccid',
                },
                {
                    path: '/extendedService/thirdPartyServer',
                    component: resolve => {
                        require(['../view/ThirdPartyServer'], resolve)
                    },
                    name: 'ThirdPartyServer',
                },
                {
                    path: '/extendedService/titleConfig',
                    component: resolve => {
                        require(['../view/TitleConfig'], resolve)
                    },
                    name: 'TitleConfig',
                },
                {
                    path: '/extendedService/PayParam',
                    component: resolve => {
                        require(['../view/PayParam'], resolve)
                    },
                    name: 'PayParam',
                },
            ]
        }]
    },
    {
        path:'/LargeMain',
        component:resolve=>{
            require(['../view/LargeDataScreen'],resolve)
        },
        name:"LargeDataScreen",
        children: [
            {
            path:'/LargeDataScreen',
            component:resolve=>{
                require(['../components/LargeDataBody'],resolve)
            },
            name:"LargeDataBody",
            },
            // {
            //     path:'/LargeDataScreen',
            //     component:resolve=>{
            //         require(['../components/LargeDataBodyNew'],resolve)
            //     },
            //     name:"LargeDataBody",
            //     },
        ]
    },
     {
        path: '/drawCom',
        component: resolve => {
            require(['../view/DrawCom'], resolve)
        },
        name: 'drawCom',
    },
     {
        path: '/hmiexecute/:token/:tokenId/:planId/:platType',
        component: resolve => {
            require(['../view/HMIExecute'], resolve)
        },
        name: 'hmiexecute',
    },
    {
        path: '/configuration/:token/:tokenId/:planId/:platType',
        component: resolve => {
            require(['../components/Configuration'], resolve)
        },
        name: 'configuration',
    },
    // {
    //     path: '/screenConfig',
    //     component: resolve => {
    //         require(['../view/ScreenConfig'], resolve)
    //     },
    //     name: 'ScreenConfig',
    // },
    {
        path: '/openCamera',
        component: resolve => {
            require(['../view/openCamera'], resolve)
        },
        name: 'openCamera',
    },
     {
        path: '/404',
        component: resolve => {
            require(['../view/NotFound'], resolve)
        },
        name: '404'
    },
     {
        path: '*',
        redirect: '/404'
    },
];

export default { routes }

