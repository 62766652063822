import axios from '../axios'

//行业列表
export const getIndustryList = () => {
  return axios({
    method: "get",
    url: `industry/list`,
  })
}
//网关列表分页
export const getGatewayPage = data => {
  return axios({
    method: "get",
    url: `gateway/page?size=${data.size}&current=${data.current}&networkModeId=${data.networkModeId}&gatewayName=${data.gatewayName}`,
  })
}
//网关列表不分页
export const getGatewayList = data => {
    return axios({
      method: "get",
      url: `/gateway/list?planId=${data || ''}`,
    })
}

//新增网关
export const addGateway = data => {
  return axios({
    method: "post",
    url: `gateway`,
    data: data
  })
}
//编辑网关
export const editGateway = data => {
  return axios({
    method: "put",
    url: `gateway`,
    data: data
  })
}
//删除网关
export const delGateway = data => {
  return axios({
    method: "delete",
    url: `gateway/${data}`,
  })
}
//网关详情
export const getGateway = data => {
  return axios({
    method: "get",
    url: `gateway/${data}`
  })
}
//计算网关下已选参数
export const getParamNum = data => {
  return axios({
    method: "get",
    url: `gateway/paramNum/${data}`
  })
}
// 实例设备列表
export const getSubOriginalList = data => {
  return axios({
    method: "get",
    url: `subOriginal/list?gatewayId=${data}`,
  })
}
// 新增实例设备
export const addSubOriginal = data => {
  return axios({
    method: "post",
    url: `subOriginal`,
    data: data
  })
}
// 删除实例设备
export const delSubOriginal = data => {
  return axios({
    method: "delete",
    url: `subOriginal/${data}`,
  })
}
//更新实例设备
export const editSubOriginal = data => {
  return axios({
    method: "put",
    url: `subOriginal`,
    data: data
  })
}
//更新实例设备
export const updateOrder = data => {
  return axios({
    method: "put",
    url: `subOriginal/updateOrder`,
    data: data
  })
}
// 实例设备详情
export const getSubOriginal = data => {
  return axios({
    method: "get",
    url: `subOriginal/${data}`,
  })
}

//联网方式列表
export const getNetworkModeList = data => {
  return axios({
    method: "get",
    url: `networkMode/list?networkMode=${data}`,
  })
}

//实例化设备详情
export const getOriginal = data => {
  return axios({
    method: "get",
    url: `subOriginal/parentOri/${data}`,
  })
}
// 查询服务器信息
export const getServer = data => {
  return axios({
    method: 'get',
    url:`server/${data}`
  })
}
//实列参数分页
export const getSubOriginalParamPage = data => {
  return axios({
    method: 'get',
    url: `/parameter/instance/page?isBoolean=${data.isBoolean}&size=50&current=${data.current}&originalId=${data.originalId}&paramName=&paramTag=`
  })
}

