
import axios from '../axios'
// 文件列表
export const getList = data => {
  return axios({
    method: 'get',
    url:`/historyFile/page?fileName=${data.fileName}&date=${data.date}&page=${data.size}&current=${data.current}`
  })
}

//文件生成
export const getDownLoad = data => {
  return axios({
    method: 'post',
    url: `/historyFile/asynGenerate`,
    data
  })
}
// 删除文件
export const delFile = data => {
  return axios({
    method: 'delete',
    url:`/historyFile/${data}`
  })
}
