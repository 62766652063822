// HMICloud 外部接口js
//
//  依赖
//      axios.min.js
//
//  历史
//      创建    LEE     2020/12/31
//=============================================================================
import { Loading } from 'element-ui';
import axios from 'axios';
import HMIPlan from './HMIPlanData.js'
import $store from '../../store/store'
import HMIEchoData from './HMIEchoData.js'
import { baseUrl } from '../global.js';
import gEchoData from './HMIEchoData.js';
import { Message } from 'element-ui';
import HMIPrj from "./HMIProject.js";
import HG from './HMIGlobal.js'
//-----------------------------------------------------------------------------
// 数据连接
//
const SYSTEM_ADDRESS = {
    // 登录地址
    LOGIN: baseUrl + 'login',
    // 保存文件
    SAVEFILE: baseUrl + 'configurationFile',
    // 读取文件
    //1:项目文件 2:通讯文件 3:图片
    //
    LOADFILE: baseUrl + 'configurationFile',
    //
    // 获取方案列表
    //  示例baseUrl + ：plan/list?isPublic=1
    //       其中（isPublic:是否公共，0：公共，1：私有）
    GETPLAN: baseUrl + 'plan/list',
    //
    // 根据方案ID查询网关实例列表
    //  示例baseUrl + ：gateway/list?planId=2
    SUBGETWAY: baseUrl + 'gateway/list',
    //
    // 根据网关ID查询设备实例列表
    //  示例baseUrl + ：subOriginal/list?gatewayId=2
    SUBORIGINAL: baseUrl + 'subOriginal/list',
    //
    // 根据设备实例ID查询参数实例列表
    //  示例baseUrl + ：parameter/list?subOriginalId=8
    SUBPARAMETER: baseUrl + 'parameter/allList',
    //
    // 获取DTU详情
    //     测试SN:
    //         202104000001
    //         202104000002
    //         202104000003
    //         202104000004
    //         202104000005
    //         202104000006
    //
    DTUDETAIL: baseUrl + 'dtu/',
    // 
    //websocket开启后需要调用的接口
    OPENWS: baseUrl + 'job/startData',
    // 
    //websocket开启后需要调用的接口
    CLOSEWS: baseUrl + 'job/stopData',
       // 关闭websocket
    DELECTWS: baseUrl + 'socket/closeData',
    // 
    //通过项目Id获取项目的内容
    // PROJECTCONFIG: (data) => {
    //     return baseUrl + `project/${data}/configuration`
    // },
    PROJECTDETAIL: baseUrl + "project/",
    SEARCHPARAMS: baseUrl + "attribute/list",
    PARMAKEY: baseUrl + "attribute/attributes",
    //获取图片连接请求地址
    CONFIGURATION_IMAGE: baseUrl + "configurationImg/list",
    //历史数据折线图  可配置参数 projectId paramIds date
    HISTORY_DATA: baseUrl + "project/historyData",
    //报警列表分页接口
    WARNING_TABLE: baseUrl + "alarmInfo/page",
    DEL_IMAGE: baseUrl + "configurationImg",
};
//-----------------------------------------------------------------------------
// 网络访问接口
//
var gNetInterface = {
    // 登录获取的Token
    loginToken: '',
    tokenId: '',
    // 设置Token
    setLoginToken: function (token) {
        // 登录用Token
        this.loginToken = token;
    },
    // 设置TokenId
    setTokenId: function (tokenId) {
        // 登录用Token
        this.tokenId = tokenId;
    },
    // login
    login: function () {
        // axios
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    // json
    json: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: false,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    // form
    form: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    // openWs
    openWs: function () {
        // axios
        var vInstance = axios.create({
            headers: {
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    // closeWs
    closeWs: function () {
        // axios
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    // projectConfig
    // projectConfig:  function() {
    //     // axios
    //     var vInstance = axios.create({
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //             'Authorization': this.loginToken,
    //             'Cache-Control': 'no-cache',
    //         },
    //         withCredentials: true,
    //         crossDomain: true,
    //     });
    //     // 接口
    //     return vInstance;
    // },
    projectDetail: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    searchParams: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    paramsKey: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    configurationImage: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    delImage: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    getHistoryData: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    },
    getWarningTable: function () {
        var vInstance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.loginToken,
                'Cache-Control': 'no-cache',
            },
            withCredentials: true,
            crossDomain: true,
        });
        // 接口
        return vInstance;
    }

};
//-----------------------------------------------------------------------------
// 测试用接口
//
const TestModeInterface = {
    // 是否开启测试模式
    useTestMode: true,
    // 设置测试用接口
    defaultSubGetway: function (vPlanID) {
        //
        // 网关测试数据
        //
        HMIPlan.subGateways.push({
            gatewayId: 1,
            name: '测试gateway_A',
            planId: vPlanID,
        });
        HMIPlan.subGateways.push({
            gatewayId: 2,
            name: '测试gateway_B',
            planId: vPlanID,
        });
        //
        // 设备测试数据
        //
        HMIPlan.subOriginals.push({
            subOriginalId: 10,
            name: '四路温控',
            planId: vPlanID,
            gatewayId: 1,
        });
        HMIPlan.subOriginals.push({
            subOriginalId: 20,
            name: '八路温控',
            planId: vPlanID,
            gatewayId: 1,
        });
        HMIPlan.subOriginals.push({
            subOriginalId: 30,
            name: '汇邦标准PLC',
            planId: vPlanID,
            gatewayId: 2,
        });
        //
        // 参数列表
        //
        HMIPlan.subParameters.push({
            paramId: 101,
            name: 'PV1',
            planId: vPlanID,
            subOriginalId: 10,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 102,
            name: 'SV1',
            planId: vPlanID,
            subOriginalId: 10,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 103,
            name: 'TEMP1',
            planId: vPlanID,
            subOriginalId: 10,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 201,
            name: 'PV2',
            planId: vPlanID,
            subOriginalId: 20,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 202,
            name: 'SV2',
            planId: vPlanID,
            subOriginalId: 20,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 203,
            name: 'TEMP2',
            planId: vPlanID,
            subOriginalId: 20,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 3000,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 301,
            name: '时间日期',
            planId: vPlanID,
            subOriginalId: 30,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 8013,
            dataAttr: 'R',
        });
        HMIPlan.subParameters.push({
            paramId: 302,
            name: '线圈',
            planId: vPlanID,
            subOriginalId: 30,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 1,
            dataAttr: 'RW',
        });
        HMIPlan.subParameters.push({
            paramId: 303,
            name: '寄存器',
            planId: vPlanID,
            subOriginalId: 30,
            cmdMaxValue: 60000,
            cmdMinValue: 0,
            paramTag: 'SET',
            paramTypeId: 1,
            dataAddr: 2000,
            dataAttr: 'RW',
        });
    },
};
//-----------------------------------------------------------------------------
// 测试用工程
//
// const TestModeProject = {
//     "setting": {
//         "width": 1280,
//         "height": 900
//     },
//     "curPg": 0,
//     "allPg": [
//         {
//             "type": 1,
//             "idx": 0,
//             "name": "测试画面",
//             "bgColor": "#BBE6F4",
//             "bgImage": "",
//             "ctrls": [
//                 {
//                     "type": 5,
//                     "x": 22,
//                     "y": 20,
//                     "w": 300,
//                     "h": 64,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "fontName": "黑体",
//                     "fontSize": 18,
//                     "text": "组态测试画面",
//                     "id": 0
//                 },
//                 {
//                     "type": 1,
//                     "x": 16,
//                     "y": 98,
//                     "w": 1240,
//                     "h": 12,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "lineType": 1,
//                     "lineWidth": 1,
//                     "id": 1
//                 },
//                 {
//                     "type": 2,
//                     "x": 44,
//                     "y": 122,
//                     "w": 234,
//                     "h": 76,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "bgColor": "#073473",
//                     "lineWidth": 1,
//                     "id": 2
//                 },
//                 {
//                     "type": 5,
//                     "x": 61,
//                     "y": 133,
//                     "w": 200,
//                     "h": 54,
//                     "selected": false,
//                     "foreColor": "#FFFFFF",
//                     "fontName": "黑体",
//                     "fontSize": 16,
//                     "text": "静态控件",
//                     "id": 3
//                 },
//                 {
//                     "type": 11,
//                     "x": 932,
//                     "y": 104,
//                     "w": 308,
//                     "h": 212,
//                     "selected": false,
//                     "address": "bg.png",
//                     "id": 4
//                 },
//                 {
//                     "type": 3,
//                     "x": 752,
//                     "y": 135,
//                     "w": 142,
//                     "h": 150,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "bgColor": "#650404",
//                     "lineWidth": 1,
//                     "id": 5
//                 },
//                 {
//                     "type": 6,
//                     "x": 348,
//                     "y": 364,
//                     "w": 128,
//                     "h": 128,
//                     "selected": false,
//                     "off": "light-off.png",
//                     "on": "light-on.png",
//                     "param": [
//                         [
//                             2,
//                             30,
//                             302
//                         ]
//                     ],
//                     "viewStatus": 1,
//                     "id": 6
//                 },
//                 {
//                     "type": 2,
//                     "x": 508,
//                     "y": 135,
//                     "w": 200,
//                     "h": 150,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "bgColor": "#067714",
//                     "lineWidth": 1,
//                     "id": 7
//                 },
//                 {
//                     "type": 4,
//                     "x": 350,
//                     "y": 167,
//                     "w": 112,
//                     "h": 86,
//                     "selected": false,
//                     "foreColor": "#0375D2",
//                     "startAg": "30",
//                     "endAg": "330",
//                     "lineWidth": "5",
//                     "id": 8
//                 },
//                 {
//                     "type": 2,
//                     "x": 48,
//                     "y": 356,
//                     "w": 234,
//                     "h": 76,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "bgColor": "#073473",
//                     "lineWidth": 1,
//                     "id": 9
//                 },
//                 {
//                     "type": 5,
//                     "x": 65,
//                     "y": 367,
//                     "w": 200,
//                     "h": 54,
//                     "selected": false,
//                     "foreColor": "#FFFFFF",
//                     "fontName": "黑体",
//                     "fontSize": 16,
//                     "text": "动态控件",
//                     "id": 10
//                 },
//                 {
//                     "type": 7,
//                     "x": 940,
//                     "y": 27,
//                     "w": 300,
//                     "h": 50,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "fontName": "黑体",
//                     "fontSize": 12,
//                     "timeType": 1,
//                     "id": 11
//                 },
//                 {
//                     "type": 8,
//                     "x": 544,
//                     "y": 387,
//                     "w": 192,
//                     "h": 82,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "fontName": "宋体",
//                     "fontSize": 12,
//                     "off": "btn-off.png",
//                     "on": "btn-on.png",
//                     "offText": "Off",
//                     "onText": "On",
//                     "param": [
//                         [
//                             2,
//                             30,
//                             302
//                         ]
//                     ],
//                     "viewStatus": 0,
//                     "mode": 1,
//                     "id": 12
//                 },
//                 {
//                     "type": 9,
//                     "x": 784,
//                     "y": 394,
//                     "w": 180,
//                     "h": 68,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "fontName": "宋体",
//                     "fontSize": 12,
//                     "address": "btn-normal.png",
//                     "text": "字按钮",
//                     "param": [
//                         [
//                             2,
//                             30,
//                             303
//                         ]
//                     ],
//                     "mode": 1,
//                     "cmdValue": "2",
//                     "id": 13
//                 },
//                 {
//                     "type": 12,
//                     "x": 1012,
//                     "y": 403,
//                     "w": 200,
//                     "h": 50,
//                     "selected": false,
//                     "foreColor": "#000000",
//                     "fontName": "宋体",
//                     "fontSize": 12,
//                     "border": true,
//                     "borderColor": "#000000",
//                     "bgColor": "#98E6F5",
//                     "param": [
//                         [
//                             2,
//                             30,
//                             303
//                         ]
//                     ],
//                     "readonly": false,
//                     "id": 15
//                 },
//                 {
//                     "type": 14,
//                     "x": 22,
//                     "y": 510,
//                     "w": 602,
//                     "h": 374,
//                     "selected": false,
//                     "fontName": "宋体",
//                     "fontSize": 10,
//                     "maxY": 100,
//                     "minY": 0,
//                     "unitY": 20,
//                     "unitX": 100,
//                     "foreColor": "#000000",
//                     "unitColor": "#DDDDDD",
//                     "bgColor": "#FFFFFF",
//                     "lblVisible": true,
//                     "curvesData": [
//                         {
//                             "selected": false,
//                             "idx": 0,
//                             "param": [
//                                 1,
//                                 10,
//                                 103
//                             ],
//                             "text": "数据0",
//                             "color": "#0000FF",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": {
//                                 "vY": [
//                                     0.52,
//                                     0.35,
//                                     0.96,
//                                     0.1,
//                                     0.18,
//                                     0.91
//                                 ]
//                             }
//                         },
//                         {
//                             "selected": false,
//                             "idx": 1,
//                             "param": [
//                                 1,
//                                 20,
//                                 203
//                             ],
//                             "text": "数据1",
//                             "color": "#FF0000",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": {
//                                 "vY": [
//                                     0.77,
//                                     0.64,
//                                     0.42,
//                                     0.39,
//                                     0.28,
//                                     0.82
//                                 ]
//                             }
//                         },
//                         {
//                             "selected": false,
//                             "idx": 2,
//                             "param": [
//                                 2,
//                                 30,
//                                 303
//                             ],
//                             "text": "数据2",
//                             "color": "#00CC00",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": {
//                                 "vY": [
//                                     0.53,
//                                     0.78,
//                                     0.35,
//                                     0.04,
//                                     0.24,
//                                     0.08
//                                 ]
//                             }
//                         }
//                     ],
//                     "id": 16
//                 },
//                 {
//                     "type": 15,
//                     "x": 642,
//                     "y": 510,
//                     "w": 622,
//                     "h": 374,
//                     "selected": false,
//                     "fontName": "宋体",
//                     "fontSize": 10,
//                     "maxY": 100,
//                     "minY": 0,
//                     "unitY": 20,
//                     "foreColor": "#000000",
//                     "unitColor": "#DDDDDD",
//                     "bgColor": "#FFFFFF",
//                     "lblVisible": true,
//                     "columnsData": [
//                         {
//                             "selected": false,
//                             "idx": 0,
//                             "param": [
//                                 1,
//                                 10,
//                                 103
//                             ],
//                             "text": "数据0",
//                             "color": "#0000FF",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": 36
//                         },
//                         {
//                             "selected": false,
//                             "idx": 1,
//                             "param": [
//                                 1,
//                                 20,
//                                 203
//                             ],
//                             "text": "数据1",
//                             "color": "#FF0000",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": 67
//                         },
//                         {
//                             "selected": false,
//                             "idx": 2,
//                             "param": [
//                                 2,
//                                 30,
//                                 303
//                             ],
//                             "text": "数据2",
//                             "color": "#00CC00",
//                             "min": 0,
//                             "max": 100,
//                             "$editData": 39
//                         }
//                     ],
//                     "id": 17
//                 }
//             ],
//             "selectedCnt": 0,
//             "offside": {
//                 "x": 90,
//                 "y": 90
//             },
//             "maxCtrlID": 18
//         }
//     ],
//     "modified": true,
//     "refreshDraw": false,
//     "allImgs": [ ]
// };
//-----------------------------------------------------------------------------
// 接口
//
var gInterface = {
    //
    // 接口中的相关属性
    //
    // 当前方案的ID
    planID: 1,
    // 登录成功函数
    loginSuccess: null,
    // 登录失败函数
    loginErr: null,
    // 读取通讯文件成功函数
    readCommSuccess: null,
    // 用户登录
    login: function (sUser, sPwd) {
        // axios login
        let instance = gNetInterface.login();
        // post value
        let vPostValue = 'username=' + sUser + '&password=' + sPwd;
        // 登录
        instance.post(SYSTEM_ADDRESS.LOGIN, vPostValue)
            .then(function (response) {
                // 检查是否存在data
                if (response.data) {
                    // 检查是否存在data
                    if (response.data.data) {
                        // 检查Token是否存在
                        if (response.data.data.token) {
                            // 设置Token
                            gNetInterface.setLoginToken(response.data.data.token);
                            gNetInterface.setTokenId(response.data.data.tokenId);
                        }
                        // 读取项目文件
                        gInterface.loadProject();
                        // 读取通讯文件
                        gInterface.loadPrjCOMM();
                        // 查询网关
                        gInterface.searchSubGetway();
                        //其他参数
                        gInterface.searchParams()
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                // 登录失败
                if (gInterface.loginErr) {
                    // 失败
                    gInterface.loginErr();
                }
            });
    },
    // 获取登录Token
    getLoginToken: function () {
        // 登录的Token
        return gNetInterface.loginToken;
    },
    // 用户直接登录
    loginDirect: function (sToken) {
        // 检查sToken是否有效
        if (sToken) {
            // 设置Token
            gNetInterface.setLoginToken(sToken);

            // 读取项目文件
            gInterface.loadProject();
            // 读取通讯文件
            gInterface.loadPrjCOMM();
            // 查询网关
            gInterface.searchSubGetway();

            gInterface.searchParams()
        }
    },
    // 保存图片文件
    saveImage: function (file, fileSaveOK, fileSaveErr) {
        // axios login
        let instance = gNetInterface.form();
        // 参数
        var params = new FormData();
        // post 参数
        params.append('fileType', 5);
        params.append('planId', this.planID);
        params.append('fileName', file.name);
        params.append('file', file, file.name);
        // 保存图片文件
        instance.put(SYSTEM_ADDRESS.SAVEFILE, params)
            .then(function (response) {
                // 检查是否存在data
                if (response.data) {
                    // 保存图片文件成功
                    if (fileSaveOK) {
                        // 保存成功
                        fileSaveOK();
                    }
                }
            })
            .catch(function (error) {
                // 输出错误
                console.log(error);
                // 保存文件失败
                if (fileSaveErr) {
                    // 保存文件失败
                    fileSaveErr();
                }
            });
    },
    // 读取图片文件
    loadImage: function (fileName, loadFileOK, loadFileErr) {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.LOADFILE;
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'fileType=5';
        vLoadURL += '&';
        vLoadURL += 'planId=';
        vLoadURL += this.planID;
        vLoadURL += '&';
        vLoadURL += 'fileName=';
        vLoadURL += fileName;
        // 读取图片文件
        instance.get(vLoadURL)
            .then(function (request) {
                // 获取图片路径
                if (request.data) {
                    // 获取图片路径
                    if (request.data.data) {
                        // 检查文件是否读取成功
                        if (loadFileOK) {
                            // 读取文件成功
                            loadFileOK(request.data.data);
                        }
                    }
                }
            })
            .catch(function (error) {
                // 读取文件失败时的错误信息
                console.log(error);
                // 读取文件失败
                if (loadFileErr) {
                    // 读取文件失败
                    loadFileErr();
                }
            });
    },
    // 保存通讯内容
    savePrjCOMM: function (planID, sCOMMData) {
        // axios login
        let instance = gNetInterface.form();
        // 项目文件创建
        var blob = new Blob([sCOMMData], { type: 'application/json' });
        // 参数
        // console.log(JSON.parse(sCOMMData));
        var params = new FormData();
        // post 参数
        params.append('fileType', + ($store.state.platType == 'pc' ? 2 : 4));
        params.append('planId', planID);
        params.append('file', blob, 'comm_file.json');

        // 保存通讯文件内容
        instance.put(SYSTEM_ADDRESS.SAVEFILE, params)
            .then(function (response) {
                // 保存通讯文件成功s
                // console.log(response);
                if (response.data.code == 200) {
                    // Message({
                    //     type: "success",
                    //     message: response.data.msg
                    // });
                } else {
                    //  Message({
                    //     type: "warning",
                    //     message: response.data.msg
                    // });
                }
            })
            .catch(function (error) {
                console.log(error);
                // Message({
                //   type: "error",
                //   message: error.msg
                // });
            });
    },
    // 保存项目内容
    saveProject: function (planID, sProject) {
        // axios login
        let instance = gNetInterface.form();
        // 项目文件创建
        // console.log(JSON.parse(sProject));
        var blob = new Blob([sProject], { type: 'application/json' });
        // 参数
        var params = new FormData();
        // post 参数
        params.append('fileType', + ($store.state.platType == 'pc' ? 1 : 3));
        params.append('planId', planID);
        params.append('file', blob, 'project_file.json');

        // 保存项目内容
        instance.put(SYSTEM_ADDRESS.SAVEFILE, params)
            .then(function (response) {
                // 保存项目文件成功
                // console.log(response);
                if (response.data.code == 200) {
                    Message({
                        type: "success",
                        message: response.data.msg
                    });
                } else {
                    Message({
                        type: "warning",
                        message: response.data.msg
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Message({
                    type: "error",
                    message: error.msg
                });
            });
    },
    // 读取项目内容
    loadProject: function () {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.LOADFILE;
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'fileType=' + + ($store.state.platType == 'pc' ? 1 : 3);
        vLoadURL += '&';
        vLoadURL += 'planId=';
        vLoadURL += this.planID;
        // 读取项目内容
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    // 错误状态
                    if (request.data.code != 200) {
                        // 登录失败
                        if (gInterface.loginErr) {
                            // 失败
                            gInterface.loginErr();
                        }
                    }
                    // 有效时
                    else if (request.data.data) {
                        // 读取详细内容
                        // console.log(request.data.data);
                        gInterface.loadProjectDetail(request.data.data);
                    }
                }
            })
            .catch(function (error) {
                // 是否使用测试模式
                if (TestModeInterface.useTestMode) {
                    // 加载项目失败时读取测试模式工程
                    console.log('LOAD TEST PROJECT');
                    // 读取项目失败时，加载测试数据
                    gInterface.loginSuccess(TestModeProject);
                }
                else {
                    // 加载项目失败时的错误信息
                    console.log(error);
                }
            });
    },
    // 读取项目内容详细
    loadProjectDetail: function (vUrl) {
        // axios json
        let instance = gNetInterface.json();
        // 读取项目详细
        instance.get(vUrl)
            .then(function (request) {
                if (request.data) {
                    // 登录成功函数
                    if (gInterface.loginSuccess) {
                        // 读取项目内容成功
                        // 加载项目文件
                        gInterface.loginSuccess(request.data);
                    }
                }
            })
            // .catch(function (error) {
            //     // 是否使用测试模式
            //     if (TestModeInterface.useTestMode) {
            //         // 加载项目失败时读取测试模式工程
            //         console.log('LOAD TEST PROJECT!');
            //         // 读取项目失败时，加载测试数据
            //         gInterface.loginSuccess();
            //     }
            //     else {
            //         // 加载项目失败时的错误信息
            //         console.log(error);
            //     }
            // });
    },
    // 根据方案ID查询网关实例列表
    searchSubGetway: function () {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.SUBGETWAY;
        // data
        let vData = null;

        // 清除Plan数据
        HMIPlan.resetData();
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'planId=';
        vLoadURL += this.planID;
        // 读取项目内容
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 获取data
                        vData = request.data.data;
                        // 添加subgetway
                        gInterface.addSubGetway(request.data.data);
                    }
                }
                // 是否使用测试模式
                if (TestModeInterface.useTestMode) {
                    // 检查数据是否存在
                    if (vData) {
                        if (vData.length === 0) {
                            // 清空
                            vData = null;
                        }
                    }
                    // 检查是否存在有效数据
                    if (null === vData) {
                        // 添加默认数据
                        TestModeInterface.defaultSubGetway(gInterface.planID);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    // 添加subgetway
    addSubGetway: function (vArraySubGateway) {
        let lItem = null;
        let valid = false;
        // 检查数量
    
        for (let vIdx = 0; vIdx < vArraySubGateway.length; vIdx++) {
            // 获取详细项目
            lItem = vArraySubGateway[vIdx];
            // 检查是否有效
            if (null != lItem) {
                // 是否有效
                valid = false;
                // 是否存在
                if ((lItem.gatewayId) || (lItem.gatewayId === 0)) {
                    valid = true;
                }
                // 是否存在
                if (lItem.subGatewayName) {
                    if (valid) {
                        valid = true;
                    }
                }
                // 是否有效
                if (valid) {
                    // 添加数据
                    HMIPlan.subGateways.push({
                        gatewayId: lItem.gatewayId,
                        name: lItem.gatewayName,
                        planId: this.planID,
                    });
                    // 搜索
                    this.searchSubOriginal(lItem.gatewayId);
                }
            }
        }
    },
    // 根据网关ID查询设备实例列表
    searchSubOriginal: function (vSubGatewayId) {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.SUBORIGINAL;
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'gatewayId=';
        vLoadURL += vSubGatewayId;
        // 读取项目内容
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 添加original
                        gInterface.addSubOriginal(request.data.data, vSubGatewayId);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    // 添加Original
    addSubOriginal: function (vArraySubOriginal, vSubGatewayId) {
        let lItem = null;
        let valid = false;
        // 检查数量
        for (let vIdx = 0; vIdx < vArraySubOriginal.length; vIdx++) {
            // 获取详细项目
            lItem = vArraySubOriginal[vIdx];
            // 检查是否有效
            if (null != lItem) {
                // 是否有效
                valid = false;
                // 是否存在
                if ((lItem.subOriginalId) || (lItem.subOriginalId === 0)) {
                    valid = true;
                }
                // 是否存在
                if (lItem.subOriginalName) {
                    if (valid) {
                        valid = true;
                    }
                }
                // 是否有效
                if (valid) {
                    // 添加数据
                    HMIPlan.subOriginals.push({
                        subOriginalId: lItem.subOriginalId,
                        name: lItem.subOriginalName,
                        planId: this.planID,
                        gatewayId: vSubGatewayId,
                    });
                    // 搜索
                    this.searchSubParameter(lItem.subOriginalId);
                }
            }
        }
    },
    // 根据设备实例ID查询参数实例列表
    searchSubParameter: function (vSubOriginalid) {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.SUBPARAMETER;
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'subOriginalId=';
        vLoadURL += vSubOriginalid;
        vLoadURL += '&planId=';
        vLoadURL += this.planID;
        // 读取项目内容
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                
                    if (request.data.data) {
                        // 添加parameter
                        gInterface.addSubParameter(request.data.data);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    // 添加parameter
    addSubParameter: function (vArraySubParameter) {
        let lItem = null;
        let valid = false;
        // 检查数量
        for (let vIdx = 0; vIdx < vArraySubParameter.length; vIdx++) {
            // 获取详细项目
            lItem = vArraySubParameter[vIdx];
            // 检查是否有效
            if (null != lItem) {
                // 是否有效
                valid = false;
                // 是否存在
                if ((lItem.paramId) || (lItem.paramId === 0)) {
                    valid = true;
                }
                // 是否存在
                if (lItem.paramName) {
                    if (valid) {
                        valid = true;
                    }
                }
                // 是否有效
                if (valid) {
                    // 添加数据
                    HMIPlan.subParameters.push({
                        paramId: lItem.paramId,
                        name: lItem.paramName,
                        planId: this.planID,
                        subOriginalId: lItem.subOriginalId,
                        // cmdMaxValue: lItem.cmdMaxValue,
                        // cmdMinValue: lItem.cmdMinValue,
                        // paramTag: lItem.paramTag,
                        paramTypeId: lItem.paramTypeId,
                        // dataAddr: lItem.dataAddr,
                        // dataAttr: lItem.dataAttr,
                    });
                }
            }
        }
    },
    // 读取通讯内容
    loadPrjCOMM: function () {
        // axios json
        let instance = gNetInterface.json();
        // load URL
        let vLoadURL = SYSTEM_ADDRESS.LOADFILE;
        // GET URL 设置
        vLoadURL += '?';
        vLoadURL += 'fileType=' + ($store.state.platType == 'pc' ? 2 : 4);
        vLoadURL += '&';
        vLoadURL += 'planId=';
        vLoadURL += this.planID;
        // 读取项目内容
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 读取通讯详细内容
                        gInterface.loadPrjCOMMDetail(request.data.data);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    // 读取通讯详细内容
    loadPrjCOMMDetail: function (vUrl) {
        // axios json
        let instance = gNetInterface.json();
        // 读取项目详细
        instance.get(vUrl)
            .then(function (request) {
                if (request.data) {
                    // 读取通讯文件成功函数
                    if (gInterface.readCommSuccess) {
                        // 执行读取通讯文件成功
                        gInterface.readCommSuccess(request.data);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    // 查询DTU详情
    searchDTUDetail: function (vSn) {
        // 根据Sn查询
        // axios json
        let instance = gNetInterface.json();
        // 获取DTU详情
        let vLoadURL = SYSTEM_ADDRESS.DTUDETAIL;
        // GET URL 设置
        vLoadURL += vSn;
        // 获取DTU详情
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    delPage:function(page){
        let instance = gNetInterface.openWs();
        let delURL = `${SYSTEM_ADDRESS.CLOSEWS}?projectId=${$store.state.projectId}&tokenId=${$store.state.tokenId}&page=${page}`;
        instance.delete(delURL)
    },
    openWs: function (resolveFun) {
        let instance = gNetInterface.openWs();
        let vLoadURL = SYSTEM_ADDRESS.OPENWS;
        let vPage = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
        let id = {
            "tokenId": $store.state.tokenId,
            "projectId": $store.state.projectId,
            "page":HMIPrj.vHMIPrj.curPg*1+1,
            "gatewayVos": [],
            "paramIds":[]
        };
        let vCtrlData;

        for (var idx = 0; idx < vPage.ctrls.length; idx++) {
            vCtrlData = vPage.ctrls[idx];
            // 检查控件类型
            switch (vCtrlData.type) {

                // 指示灯控件
                case HG.ControlType.light:
                // 位按钮
                case HG.ControlType.bbtn:
                // 字按钮
                case HG.ControlType.wbtn:
                // 数值输入控件
                case HG.ControlType.num:
                // 文数对应
                case HG.ControlType.textLst:

                case HG.ControlType.clockDial:
                // 进度条控件
                case HG.ControlType.progressBar:
                // 图片数值对应控件
                case HG.ControlType.pictureList:
                // 图片进度条
                case HG.ControlType.panelPic:
                    //    vCtrlData.param
                    if (vCtrlData.param[0]) {
                        // 检查是否为内部寄存器
                        if(HMIPlan.PlanDataInsideParamKeys.insideParamValue == vCtrlData.param[0]) {
                            // 内部寄存器不处理
                            continue;
                        }
                        let flag = true
                        for (let i = 0; i < id.gatewayVos.length; i++) {
                            if (id.gatewayVos[i].gatewayId == vCtrlData.param[0]) {
                                flag = false;
                                let subFlag = true;
                                for (let j = 0; j < id.gatewayVos[i].soVos.length; j++) {
                                    if (id.gatewayVos[i].soVos[j].subOriginalId == vCtrlData.param[1]) {
                                        subFlag = false;
                                        let paramFlag = true;
                                        id.gatewayVos[i].soVos[j].paramIds.forEach(item => {
                                            if (item == vCtrlData.param[2]) {
                                                paramFlag = false;
                                            }
                                        })
                                        if (paramFlag) {
                                            id.gatewayVos[i].soVos[j].paramIds.push(vCtrlData.param[2])
                                            id.paramIds.push(vCtrlData.param[2])
                                        }
                                    }
    
                                }
                                if (subFlag) {
                                    id.gatewayVos[i].soVos.push({
                                        "subOriginalId": vCtrlData.param[1],
                                        "paramIds": []
                                    })
                                }
                            }
    
                        }
                        if (flag) {
                            id.paramIds.push(vCtrlData.param[2])
                            id.gatewayVos.push({
                                "gatewayId": vCtrlData.param[0],
                                "soVos": [{
                                    "subOriginalId": vCtrlData.param[1],
                                    "paramIds": [vCtrlData.param[2]]
                                }],
                            })
                        }
                    }
                   
                    break;
                // 数据表格  
                case HG.ControlType.dataTable:
                    let item
                    let dataList = vCtrlData.dataTable;
                    for (let e = 0; e < dataList.length; e++) {
                        item=dataList[e];
                        if (item.param[0]) {
                        let dataFlag = true;
                        for (let i = 0; i < id.gatewayVos.length; i++) {
                            
                            if (id.gatewayVos[i].gatewayId == item.param[0]) {
                                dataFlag = false;
                                let subFlag = true;
                                for (let j = 0; j < id.gatewayVos[i].soVos.length; j++) {
                                   
                                    if (id.gatewayVos[i].soVos[j].subOriginalId == item.param[1]) {
                                        subFlag = false;
                                        let paramFlag = true;
                                        id.gatewayVos[i].soVos[j].paramIds.forEach(ele => {
                                            if (ele == item.param[2]) {
                                                paramFlag = false;
                                            }
                                        })
                                        if (paramFlag) {
                                            id.gatewayVos[i].soVos[j].paramIds.push(item.param[2])
                                            id.paramIds.push(item.param[2])
                                        }
                                    }

                                }
                                if (subFlag) {
                                  
                                    id.gatewayVos[i].soVos.push({
                                        "subOriginalId": item.param[1],
                                        "paramIds": []
                                    })
                                }
                            }

                        }
                            if (dataFlag) {
                                id.paramIds.push(item.param[2])
                            id.gatewayVos.push({
                                "gatewayId": item.param[0],
                                "soVos": [{ 
                                     "subOriginalId": item.param[1],
                                "paramIds": [item.param[2]]
                            }],
                            })
                            
                        }
                    }
                    }

                    break;
           
                    default:
                        break;
                }

        }
    
        if (id.gatewayVos.length>0) {
                instance.post(vLoadURL,
            id
        ).then(() => {
            // resolveFun(true)
        }) 
         }
           
        
       
       
    },
    closeWs: function () {

        let instance = gNetInterface.closeWs();
        let delURL = `${SYSTEM_ADDRESS.CLOSEWS}?projectId=${$store.state.projectId}&tokenId=${$store.state.tokenId}&page=${HMIPrj.vHMIPrj.curPg*1+1}`;
        instance.delete(delURL)
        let vLoadURL = `${SYSTEM_ADDRESS.DELECTWS}?projectId=${$store.state.projectId}&tokenId=${$store.state.tokenId}`;
        instance.delete(vLoadURL)
    },
    projectDetail: function (projectId, resolve) {
        // axios projectDetail
        let instance = gNetInterface.projectDetail();
        // 获取DTU详情
        let vLoadURL = SYSTEM_ADDRESS.PROJECTDETAIL;
        // GET URL 设置
        vLoadURL += projectId;
        // 获取项目详情
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.devidceData = request.data.data;
                        HMIEchoData.status.devidceData = (new Date()).getTime();
                    }
                }
                resolve(true)
            })
            .catch(function (error) {
                console.log(error);
                resolve(true)

            });
    },
    searchParams: function (interfaceId = "") {
        // axios searchParams
        let instance = gNetInterface.searchParams();
        // 获取DTU详情
        let vLoadURL = SYSTEM_ADDRESS.SEARCHPARAMS;
        vLoadURL += "?interfaceId=" + interfaceId
        // GET URL 设置
        // 获取DTU详情
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIPlan.itfData = request.data.data
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    paramsKey: function () {
        let instance = gNetInterface.paramsKey();
        let vLoadURL = SYSTEM_ADDRESS.PARMAKEY;

        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.paramskey = request.data.data
                    }
                }
            })
            .catch(function (error) {
                console.log(error);

            });
    },
    configurationImage: function () {
        let instance = gNetInterface.configurationImage();
        let vLoadURL = SYSTEM_ADDRESS.CONFIGURATION_IMAGE;

        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.imageList = request.data.data
                    }
                }
            })
            .catch(function (error) {
                console.log(error);

            });
    },
    getHistoryData: function (param, resolve,date) {
        let loadingInstance = Loading.service({
            lock: true,
            text: '加载中，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let instance = gNetInterface.getHistoryData();
        let vLoadURL = SYSTEM_ADDRESS.HISTORY_DATA;
        instance.post(vLoadURL, {
            projectId: Number($store.state.projectId),
            ctrls: param,
            date
        })
            .then(function (request) {
                loadingInstance.close()
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.historyData = request.data.data;
                        HMIEchoData.status.historyData = (new Date()).getTime()
                        resolve(true);
                    }
                }

            })
            .catch(function (error) {
                // console.log(error);
                resolve(true)
            });
    },
    getSingleHistoryData: function (param) {
        let instance = gNetInterface.getHistoryData();
        let vLoadURL = SYSTEM_ADDRESS.HISTORY_DATA;

        instance.post(vLoadURL, {
            projectId: Number($store.state.projectId),
            ctrlVos: param
        })
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.historyData.forEach((element, i) => {
                            if (element.ctrlId == request.data.data[0].ctrlId) {
                                HMIEchoData.historyData[i] = request.data.data[0];
                                HMIEchoData.status.historyData = (new Date()).getTime()
                            }
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getWarningTable: function (resolve) {
        let instance = gNetInterface.getWarningTable();
        let vLoadURL = SYSTEM_ADDRESS.WARNING_TABLE;
        vLoadURL += `?current=${gEchoData.warningForm.current}
                    &size=${gEchoData.warningForm.size}
                    &projectId=${$store.state.projectId}`
        instance.get(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.data) {
                        // 执行查询
                        HMIEchoData.warningData = request.data.data.records;
                        HMIEchoData.warningForm.totalPage = request.data.data.pages
                        HMIEchoData.status.warningData = (new Date()).getTime();
                        if (resolve) {
                            resolve(true);
                        }
                    }
                }

            })
            .catch(function () {
                if (resolve) {
                    resolve(true);
                }
            });
    },
    delImage: function (imgName) {
        let instance = gNetInterface.delImage();
        let vLoadURL = SYSTEM_ADDRESS.DEL_IMAGE;
        vLoadURL += `?fileName=${imgName}`
        instance.delete(vLoadURL)
            .then(function (request) {
                if (request.data) {
                    if (request.data.code == 200) {
                        gInterface.configurationImage()
                    }
                }

            })
            .catch(function () { });
    }
};

// 外部接口暴露
export default gInterface;
