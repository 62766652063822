// HMIExterCtrls 外部控件管理
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/07/16
//=====================================================================================
//-------------------------------------------------------------------------------------
// 外部控件管理
//
import * as echarts from 'echarts';
import HG from './HMIGlobal.js'
import HMIEchoData from './HMIEchoData.js'
// import HMIProject from './HMIProject.js';
import HMIInterface from './HMIInterface'
import gEchoData from './HMIEchoData.js';
import gInterface from './HMIInterface';
import $store from '../../store/store';
import moment from 'moment';
import $api from '../../http/api';
import { Message,Loading } from 'element-ui';
import HMIDraw from './HMIDraw.js'
import HMIPrj from "./HMIProject.js";

// import constant from '../constant'
// 外部控件管理
const cExterCtrlsMG = {
    //---------------------------------------------------------------------------------
    // 注册外部控件
    //
    register: function (vExterCtrlsType, vDOM, vEditMode = false, vCtrlHistoryCurves, isNew) {
        // 检查外部控件类型 
        switch ((vExterCtrlsType)) {
            // 历史曲线控件
            case HG.ControlType.historyCurves:
                // 检查外部控件对象是否存在
                if (!vDOM.exterCtrl) {
                    // 初始化表格
                    vDOM.exterCtrl = echarts.init(vDOM);

                    // 检查是否为编辑模式
                    if (vEditMode) {
                        // if ($store.state.platType == "pc") {
                        //     vDOM.appendChild(this.drawPicker("date", "20px", "10px", "120px", vDOM, vCtrlHistoryCurves));
                        // } else {
                        //     vDOM.appendChild(this.drawPicker("date", "20px", "10px", "105px", vDOM, vCtrlHistoryCurves));
                        // }

                        // 去除鼠标操作
                        vDOM.exterCtrl.on('click', function () { return; });
                        vDOM.exterCtrl.on('mousemove', function () { return; });
                    } else {
                        
                       
                        // vDOM.appendChild(this.drawPicker("date", "20px", "10px", "120px", vDOM, vCtrlHistoryCurves));
                       
                    }
                }
                break;
            //温度表盘空间
            case HG.ControlType.clockDial:
                // 检查外部控件对象是否存在
                if (!vDOM.exterCtrl) {
                    // 初始化表格
                    vDOM.exterCtrl = echarts.init(vDOM);
                    // 检查是否为编辑模式
                    if (vEditMode) {
                        // 去除鼠标操作
                        vDOM.exterCtrl.on('click', function () { return; });
                        vDOM.exterCtrl.on('mousemove', function () { return; });
                    }
                }
                break;
            //数据表格空间
            case HG.ControlType.dataTable:
                // 检查外部控件对象是否存在
                if (!vDOM.exterCtrl) {
                    // 初始化表格
                    if (vCtrlHistoryCurves.transparent) {
                        vDOM.style.background="transparent"
                    }else{
                        
                        vDOM.style.background=vCtrlHistoryCurves.bgColor
                    }
                //   console.log(vCtrlHistoryCurves);
                    vDOM.innerHTML = ""
                    vDOM.style.width=vCtrlHistoryCurves.w
                    vDOM.style.height=vCtrlHistoryCurves.h
                    vDOM.style.padding="10px"
                    vDOM.style.overflowY="auto";
                    vDOM.style.boxSizing="border-box"
                    // console.log(vCtrlHistoryCurves);
                    if (vCtrlHistoryCurves.dataTable.length==0) {
                        vDOM.innerHTML="暂无数据"
                        vDOM.style.textAlign="center"
                        vDOM.style.fontSize="30px"
                    }

                    vDOM.appendChild(this.drawDataTale(vCtrlHistoryCurves.dataTable,vCtrlHistoryCurves,isNew,vDOM));
                //    vDOM.children.on('click', function () { return; });
                //     vDOM.children.on('mousemove', function () { return; });

                        // 去除鼠标操作
                        // vDOM.exterCtrl.on('click', function () { return; });
                        // vDOM.exterCtrl.on('mousemove', function () { return; });
                        // HMIDraw.valueNew=false
                }
                break;
                //告警表格
            case HG.ControlType.warningTable:
                if (!vDOM.exterCtrl) {
                    //这里绘制table的样式 可以使用dom
                    if (isNew || (new Date()).getTime() - HMIEchoData.status.warningData < 200) {
                       
                        if (window.location.href.indexOf("hmiexecute") > -1) {
                            vDOM.style.paddingTop = "100px"
                        }
                        vDOM.innerHTML = ""
                        vDOM.appendChild(this.drawTablePage(vEditMode,vCtrlHistoryCurves));
                        vDOM.appendChild(this.drawPaging("prev", "&#xe692;"));
                        vDOM.appendChild(this.drawPaging("next", "&#xe645;"));
                        vDOM.style.textAlign = "center"
                    }
                }
                break;
            default:
                //
                // 其他控件不支持此函数
                //
                return;
        }
    },
    //数据表格内容
    drawDataTale:function (tableDate,vCtrlHistoryCurves,isNew,vDOM) {
  
       let table = document.createElement("table");
      
        table.classList.add("table");
        table.style.width = "100%"
        table.style.fontFamily=vCtrlHistoryCurves.fontName;
        table.style.fontSize="0";
        table.style.color=vCtrlHistoryCurves.colors;
        if (isNew) {
            table.onclick=()=>{
                vCtrlHistoryCurves.selected=true;
           };
        let vCtrlX=vCtrlHistoryCurves.x
        let vCtrlY=vCtrlHistoryCurves.y
           table.onmousedown=(event)=>{
            vCtrlHistoryCurves.selected=true
            // vCtrlHistoryCurves.x
            let x=event.clientX;
            let y=event.clientY;
            
            window.onmousemove=(event)=>{
                let editX=event.clientX-x
                let editY=event.clientY-y
                // console.log(editX,editY);
                vCtrlHistoryCurves.x=vCtrlX+editX
                vCtrlHistoryCurves.y=vCtrlY+editY
              
                // HMIDraw.valueNew=false
            }
           };
           table.onmouseup=()=>{
            window.onmousemove=null;
            table.onmousedown=null;
            HG.$Set.projectStatus.toolbarStatus=true
                HMIDraw.valueNew=true
                
            }
            window.onmouseup=()=>{
                window.onmousemove=null;
                table.onmousedown=null;
                HG.$Set.projectStatus.toolbarStatus=true
                    HMIDraw.valueNew=true  
                }
        }
            
       
        tableDate.forEach(element => {
        let tbody = table.createTBody();
        tbody.style.boxSizing="border-box"
        tbody.style.lineHeight = (vCtrlHistoryCurves.fontSize*1+16)*HMIDraw.settings.displayZoom +"px"
        tbody.style.width = "100%"
        tbody.style.border="1px solid #999"
        let td1 = document.createElement("td");
        if (element.paramState==2) {
            td1.style.color="red"
           }else if (element.paramState==1) {
            td1.style.color=vCtrlHistoryCurves.colors;
           }
        td1.style.width = "50%";
        td1.style.textAlign = "center";
        td1.style.fontSize=vCtrlHistoryCurves.fontSize*HMIDraw.settings.displayZoom +'px';
        td1.style.borderBottom="1px solid #999"
        
        td1.innerHTML=element.nameValue;
            tbody.append(td1);
            tbody.append(this.drawDateTableTd(element,vCtrlHistoryCurves,isNew));
            // tbody.append(this.drawOperation(element,vCtrlHistoryCurves,isNew));
        });
        // div.append(table);
       return table
    },
   input(){
    
   },

    drawDateTableTd:function (item,vCtrlHistoryCurves,isNew) {
      
        let td = document.createElement("td");
        if (item.typeValue=="true"&&item.reading=="false"){
            td.style.textDecoration='underline';
        }
        td.style.width = vCtrlHistoryCurves.w/2+'px';
        td.style.height=(vCtrlHistoryCurves.fontSize*1+16)*HMIDraw.settings.displayZoom +"px"
        td.style.textAlign = "center";
        td.style.borderBottom="1px solid #999"
        td.style.fontSize=vCtrlHistoryCurves.fontSize*HMIDraw.settings.displayZoom +'px';
        td.style.lineHeight=vCtrlHistoryCurves.fontSize*HMIDraw.settings.displayZoom +'px'
       if (item.paramState==2) {
        td.style.color="red"
       }else if (item.paramState==1) {
        td.style.color=vCtrlHistoryCurves.colors;
        if (item.typeValue=="true"&&item.reading=="false"){
            td.style.color="rgb(64, 158, 255)";
        }
       }
       if (item.value!=undefined&&!isNew) {
          
           td.onclick=()=>{
           
            if (item.reading=="true") {

                return
            }else if(item.reading=="false") {
 
                if (item.typeValue=="true") {
                    
                    this.input(item);
                    return
                }else if (item.typeValue=="false"){
                    //判断数据类型
                    
                    if (item.state==1) {
                        item.value = 1;
                    }else if (item.state==2){
                        item.value = 0;
                    }else if (item.state==3){
                        if (0 == item.value) {
                            // 交替
                            item.value = 1;
                        } else {
                            // 交替
                            item.value = 0;
                        }
                     
                    }
                    // 设置默认数据值
                    let _d = {
                        projectId: $store.state.projectId,
                        gatewayId: item.param[0],
                        subOriginalId: item.param[1],
                        paramId: item.param[2],
                        value: Number(item.value),
                        page:HMIPrj.vHMIPrj.curPg*1+1
                    };
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: '下发中，请稍后...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(255, 255, 255, 0.5)',
                        target:document.querySelector('.el-dialog__body')
                    });
                    $api.project.sendData(_d).then(response => {
                        loadingInstance.close()
                        // console.log(response);
                        if (response.code == 200) {
                            // vCtrl.dataValue = vData;
                            //
                            // 执行数据操作
                            //
                            // HMIDataPool.setCOMMData(vCtrl.param, vData);
                            // alert("动作以提交，请等待数据更新，勿重复点击！")
                            Message({
                                type: "success",
                                message: "动作已提交，请等待数据更新，勿重复点击！",
                            });
                        } 
                    }).catch(() => {
                        alert("下发失败，请重试！")
                    })
                  
                }

            }
            
        }
       }
        //判断是数据还是布尔
        if (item.typeValue=="true") {
            if (item.value==undefined) {
                 td.innerHTML="--"+item.company 
            }else{
               td.innerHTML=item.value +item.company
              
        }
            
        }else if (item.typeValue=="false"){
            
            if (item.value==0||item.value==undefined) {
            if (item.off=="light-off.png") {
                td.innerHTML=`<img src="https://oss.cniot.fun/iot-system/sys-program/7-2.png"  style="height:${(vCtrlHistoryCurves.fontSize*1+8)*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
            }else if (item.off=="btn-off.png"){
                td.innerHTML=`<img src="https://oss.cniot.fun/iot-system/sys-program/9-1.png" style="height:${vCtrlHistoryCurves.fontSize*1*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
            }else{
                td.innerHTML=`<img src="${item.off}" style="height:${(vCtrlHistoryCurves.fontSize*1+8)*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
            }
            }else if (item.value==1){
                if (item.on=="light-on.png") {
                    td.innerHTML=`<img src="https://oss.cniot.fun/iot-system/sys-program/7-4.png" style="height:${(vCtrlHistoryCurves.fontSize*1+8)*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
                }else if (item.on=="btn-on.png"){
                    td.innerHTML=`<img src="https://oss.cniot.fun/iot-system/sys-program/9-3.png" style="height:${vCtrlHistoryCurves.fontSize*1*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
                }else{
                    td.innerHTML=`<img src="${item.on}" style="height:${(vCtrlHistoryCurves.fontSize*1+8)*HMIDraw.settings.displayZoom +"px"};width:auto;user-select:none;"/>`
                }
            }
            
        }
        return td
    },
   
    // drawOperation:function (item,vCtrlHistoryCurves,isNew) {
    //     let td = document.createElement("td");
    //     td.style.width = vCtrlHistoryCurves.w/3+'px';
    //     td.style.height=(vCtrlHistoryCurves.fontSize*1+16)*HMIDraw.settings.displayZoom +"px"
    //     td.style.lineHeight=vCtrlHistoryCurves.fontSize*HMIDraw.settings.displayZoom +'px'
    //     td.style.textAlign = "center";
    //     td.style.borderBottom="1px solid #999"
    //     td.style.fontSize=vCtrlHistoryCurves.fontSize*HMIDraw.settings.displayZoom +'px';
    //     if (!isNew) {
    //         td.onclick=()=>{
    //         if (item.reading=="true") {
    //             return
    //         }else if(item.reading=="false") {
    //             if (item.typeValue=="true") {
    //                 this.input(item);
    //                 return
    //             }else if (item.typeValue=="false"){
    //                 //判断数据类型
    //                return
                  
    //             }
    //         }
    //     }
           
            
    //     }
    //     if (item.typeValue=="true"&&item.reading=="false") {
    //       td.innerHTML=`
     
    //     <svg t="1638516758345"  class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2530" width="${(vCtrlHistoryCurves.fontSize*1+6)*HMIDraw.settings.displayZoom +"px"}" height="${(vCtrlHistoryCurves.fontSize*1+6)*HMIDraw.settings.displayZoom +"px"}"><path d="M684.202667 117.248c15.893333-15.872 42.154667-15.36 58.922666 1.408l90.517334 90.517333c16.661333 16.661333 17.344 42.986667 1.429333 58.922667l-445.653333 445.653333c-7.936 7.914667-23.104 16.746667-34.218667 19.776l-143.701333 39.253334c-21.909333 5.994667-35.114667-7.104-29.568-28.949334l37.248-146.773333c2.773333-10.944 11.562667-26.346667 19.392-34.176l445.653333-445.653333zM268.736 593.066667c-2.901333 2.901333-8.106667 12.074667-9.130667 16.021333l-29.12 114.773333 111.957334-30.570666c4.437333-1.216 13.632-6.549333 16.810666-9.728l445.653334-445.653334-90.517334-90.496-445.653333 445.653334zM682.794667 178.986667l90.517333 90.517333-30.186667 30.186667-90.496-90.517334 30.165334-30.165333z m-362.026667 362.048l90.496 90.517333-30.165333 30.165333-90.517334-90.496 30.165334-30.186666zM170.666667 874.666667c0-11.776 9.429333-21.333333 21.461333-21.333334h661.077333a21.333333 21.333333 0 1 1 0 42.666667H192.128A21.333333 21.333333 0 0 1 170.666667 874.666667z" fill="#515151" p-id="2531"></path></svg>  

    //     `  
    //     }else{
    //         td.innerHTML=""
    //     }
        
    //     return td
    // },
    



    //---------------------------------------------------------------------------------
    // 表格分页绘制
    //
    drawTablePage: function () {
        let tableData = gEchoData.warningData.length > 0 ? gEchoData.warningData : gEchoData.testTableData;
        let table = document.createElement("table");
        table.classList.add("table");
        table.style.width = "100%"
      
        let thead = table.createTHead();
        thead.style.width = "100%"
        thead.style.height = "50px"

        let thArr = ["开始时间", "结束时间", "告警时长", "告警内容", "告警状态"];
        thArr.forEach(element => {
            var th = document.createElement("th");
            th.style.width = "14.2%";
            th.style.height = "50px";
            th.style.lingHeight = "50px";
            th.style.textAlign = "center";
            th.title = element;

            th.append(this.drawP(element));
            thead.append(th);
        });

        tableData.forEach(element => {
            let tbody = table.createTBody();
            tbody.style.lineHeight = "22px"
            thead.style.width = "100%"
            // thead.style.height = "50px"
            tbody.append(this.drawtd(this.drawP(element.startTime)));
            tbody.append(this.drawtd(this.drawP(element.endDate)));
            tbody.append(this.drawtd(this.drawP(element.alarmDuration)));
            tbody.append(this.drawtd(this.drawP(element.alarmInfo)));
            tbody.append(this.drawtd(this.drawP(element.alarmState)));
        });

        return table
    },
    drawPaging: function (type, icon) {
        let btn = document.createElement("i");
        btn.classList.add("iconfont")
        btn.style.cursor = 'pointer'
        btn.style.fontSize = "20px"
        // btn.style.float = "right"
        btn.innerHTML = icon
        btn.addEventListener("click", function (event) {
            let current = gEchoData.warningForm.current;
            let totalPage = gEchoData.warningForm.totalPage;
            switch (type) {
                case "prev":
                    if (current > 1) {
                        gEchoData.warningForm.current--;
                        gInterface.getWarningTable();
                    }
                    break;
                case "next":
                    if (current < totalPage) {
                        gEchoData.warningForm.current++;
                        gInterface.getWarningTable();
                    }
                    break;
                default:
                    alert("错误操作!")
                    break;
            }

            event.stopPropagation();
        })

        return btn
    },
    drawtd: function (node) {
        let td = document.createElement("td");
        td.style.width = "14.2%";
        td.style.textAlign = "center";
        td.append(node);
        return td
    },
    drawP: function (element) {
        let p = document.createElement("p");
        p.style.width = "100%";
        p.innerHTML = element;
        p.classList.add("overP")
        return p
    },
    //---------------------------------------------------------------------------------
    // 时间选择器绘制
    //
    drawPicker: function (type, left, top, width, vDOM, vCtrlHistoryCurves) {
        let picker = document.createElement("input");
        // let data=new Date();
        // let next1=`${data.getFullYear()}`+'-'+`${(data.getMonth()+1)}`+'-'+`${data.getDate()}`;
        // picker.max=next1;
        picker.type = type
        picker.style.width = width;
        picker.style.height = '20px';
        picker.style.top = top;
        picker.style.left = left;
        picker.style.background = "#FFFFFF"
        // picker.style.zIndex = "20"
        picker.style.position = "absolute"
        picker.style.borderRadius = "4px"
        picker.style.borderStyle = "solid"
        picker.style.borderWdth = "1px"
        // picker.style.borderColor = "#dcdfe6"

        if (type == 'date') {
            //日期
            picker.value = moment().format("yyyy-MM-DD")
            picker.max = moment().format("yyyy-MM-DD")
        } else if (type == 'time') {
            //时间
            picker.value = moment().format("HH:mm")
            picker.max = moment().format("HH:mm")
        }

        picker.addEventListener("change", function () {
            HMIEchoData.historyObj.forEach(element => {
                if (element.ctrlId == vCtrlHistoryCurves.id) {
                    let httpObj = element;

                    switch (this.type) {
                        case "date":
                            httpObj.date = `${this.value}`;
                        //     httpObj.date = `${this.value}T${httpObj.date.split("T")[1]}`;
                        //     break;
                        // case "time":
                        //     httpObj.date = `${httpObj.date.split("T")[0]}T${this.value}:00`;
                        //     break;
                    }

                    HMIInterface.getSingleHistoryData([httpObj])
                }
            });
        })
        return picker
    },
    //---------------------------------------------------------------------------------
    // 设置外部控件的标志
    //
    setOutCtrlSign: function (vDOM) {
        // 检查是否存在
        if (!vDOM.isOutCtrl) {
            vDOM.isOutCtrl = true;
        }
        // children
        let vChildren = vDOM.children;
        let vCtrl = null;
        // 设置子控件
        for (let idx = 0; idx < vChildren.length; idx++) {
            // 获取子控件
            vCtrl = vChildren[idx];
            // 检查是否存在
            if (!vCtrl.isOutCtrl) {
                vCtrl.isOutCtrl = true;
            }
            // 检查是否存在子控件
            if (vCtrl.children) {
                // 检查是否存在子控件
                if (vCtrl.children.length > 0) {
                    // 设置外部控件的标志
                    this.setOutCtrlSign(vCtrl);
                }
            }
        }
    },
    //---------------------------------------------------------------------------------
    // 设置外部控件的数据参数
    //
    setParam: function (vExterCtrlsType, vDOM, vAttribute, vValue = null, ctrlId = null) {
        // 检查外部控件类型
        switch (vExterCtrlsType) {
            //温度表盘控件
            case HG.ControlType.clockDial:
                // 检查外部控件对象是否存在
                if (vDOM.exterCtrl) {
                    switch (vAttribute) {
                        //设置初始数据
                        case 'testData':

                            vDOM.option = {
                                series: {
                                    type: "gauge",
                                    center: ["50%", "60%"],
                                    startAngle: 200,
                                    endAngle: -20,
                                    min: 0,
                                    max: 100,
                                    splitNumber: 10,
                                    itemStyle: {
                                        //颜色
                                        color: "#000",
                                    },
                                    pointer: {
                                        show: false
                                    },
                                    progress: {
                                        //显示温度
                                        show: true,
                                        width: 10,
                                    },

                                    axisLine: {
                                        lineStyle: {
                                            //温度宽度
                                            width: 10,
                                        },
                                    },
                                    axisTick: {
                                        //刻度位置
                                        distance: -22,
                                        splitNumber: 5,
                                        lineStyle: {
                                            width: 2,
                                            color: "#999",
                                        },
                                    },
                                    splitLine: {
                                        //数字刻度位置
                                        distance: -22,
                                        length: 14,
                                        lineStyle: {
                                            width: 3,
                                            color: "#999",
                                        },
                                    },
                                    axisLabel: {
                                        //刻度数字大小
                                        distance: -20,
                                        color: "#000",
                                        fontSize: 20,
                                    },

                                    detail: {
                                        //中间温度字体大小
                                        valueAnimation: true,
                                        width: "60%",
                                        lineHeight: 40,
                                        borderRadius: 8,
                                        offsetCenter: [0, "0"],
                                        fontSize: 60,
                                        fontWeight: "bolder",
                                        formatter: "{value} °C",
                                        color: "auto",
                                    },
                                    data: [
                                        //温度值
                                        {
                                            value: 0,
                                        },
                                    ],
                                },
                            };

                            // 设置option
                            vDOM.exterCtrl.setOption(vDOM.option);
                            // 设置外部控件的标志
                            this.setOutCtrlSign(vDOM);
                            break;

                        case 'data':
                            if ((new Date()).getTime() - HMIEchoData.status.wsData < 100) {
                                vDOM.option.series.data[0].value = vValue;

                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;

                        case 'min':
                            // 检查字体是否一致
                            if (vValue != vDOM.option.series.min) {
                                // 设置option字体
                                vDOM.option.series.min = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'color':
                            // 检查颜色是否一致
                            if (vValue != vDOM.option.series.itemStyle.color) {
                                // 设置颜色
                                vDOM.option.series.itemStyle.color = vValue;
                                // 标签颜色
                                vDOM.option.series.axisLabel.color = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'max':
                            // 检查字体是否一致

                            if (vValue != vDOM.option.series.max) {
                                // 设置option字体
                                vDOM.option.series.max = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'fontSize':
                            // 检查字体是否一致
                            if (vValue != vDOM.option.series.detail.fontSize) {
                                // 设置option字体
                                vDOM.option.series.detail.fontSize = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'symbol':
                            // 检查字体是否一致
                           
                            if (vValue != vDOM.option.series.detail.formatter.split(" ")[1]) {
                                 if (vValue==null) {
                                    vValue="°C"
                            }
                                // 设置option字体
                                vDOM.option.series.detail.formatter ="{value}"+" "+vValue;

                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'scaleSize':
                            // 检查字体是否一致
                            if (vValue != vDOM.option.series.axisLabel.fontSize) {
                                // 设置option字体
                                vDOM.option.series.axisLabel.fontSize = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                    }

                }
                break;
            // 历史曲线控件
            case HG.ControlType.historyCurves:
                // 检查外部控件对象是否存在
                if (vDOM.exterCtrl) {
                    // 不同的属性
                    switch (vAttribute) {
                        case 'testData':
                            //
                            // 设置测试数据
                            //
                            var base = +new Date(2020, 1, 1);
                            var oneDay = 24 * 3600 * 1000;
                            // option Data
                            vDOM.optionData = [[base, Math.random() * 300]];
                            // 示例数据
                            for (let idx = 1; idx < 100; idx++) {
                                var now = new Date(base += oneDay);
                                vDOM.optionData.push([
                                    [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
                                    Math.round((Math.random() - 0.5) * 20 + vDOM.optionData[idx - 1][1])
                                ]);
                            }
                            // 设置option
                            vDOM.option = {
                                textStyle: {
                                    fontFamily: '黑体',
                                    fontSize: 12,
                                    fontStyle: 'normal',
                                    fontWeight: 'normal'
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    position: function (pt) {
                                        return [pt[0], '10%'];
                                    }
                                },
                                title: {
                                    left: 'center',
                                    text: '',
                                    textStyle: {
                                        fontSize: 20
                                    },
                                },
                                toolbox: {
                                    show: true,
                                    itemSize: 15,
                                    right: 30,
                                    top: 10,
                                    feature: {
                                        dataZoom: {
                                            show: false,
                                            yAxisIndex: 'none'
                                        },
                                        restore: { show: false },
                                        dataView: {
                                            readOnly: true,
                                            optionToContent: function (opt) {
                                              var axisData = opt.xAxis[0].data;
                                              var series = opt.series;
                                              let nameTitle = "";
                                              series.forEach((item) => {
                                                nameTitle += "<td>" + item.name + "</td>";
                                              });
                                              var table =
                                                '<table style="width:100%;text-align:center"><tbody><tr>' +
                                                "<td>时间</td>" +
                                                nameTitle +
                                                "</tr>";
                                              for (var i = 0, l = axisData.length; i < l; i++) {
                                                let valList = "";
                                                series.forEach((item) => {
                                                  let newVal = "无";
                                                  item.data.forEach((it) => {
                                                    if (it[0] == axisData[i]) {
                                                      newVal = it[1];
                                                    }
                                                  });
                                                  valList += "<td>" + newVal + "</td>";
                                                });
                                                table +=
                                                  "<tr>" + "<td>" + axisData[i] + "</td>" + valList;
                                                ("</tr>");
                                              }
                                              table += "</tbody></table>";
                                              return table;
                                            },
                                          },
                                    },
                                },
                                legend: {
                                    data: ['数据1', '数据2'],
                                    
                                    type: "scroll",
                                    y: "bottom",
                                    x: "center",
                                },
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: ["00:00:00", "00:01:00", "00:02:00", "00:03:00", "00:04:00"]
                                },
                                yAxis: {
                                    type: 'value',
                                    boundaryGap: true,
                                    boundaryGap: [0, 0],
                                },
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                    height: 10,
                                    type: 'slider',
                                    show: true,
                                    handleSize: 8,
                                    handleStyle: {
                                        shadowBlur: 4,
                                        shadowOffsetX: 1,
                                        shadowOffsetY: 1,
                                    },
                                }],
                                series: [{
                                    name: "数据1",
                                    type: "line",
                                    symbol: 'none',
                                    data: []
                                }, {
                                    name: "数据2",
                                    type: "line",
                                    symbol: 'none',
                                    data: []
                                }]
                            };
                            // 设置option
                            vDOM.exterCtrl.setOption(vDOM.option);
                            // 设置外部控件的标志
                            this.setOutCtrlSign(vDOM);
                            break;
                        case 'data':
                           
                            if ((new Date()).getTime() - HMIEchoData.status.historyData < 4000) {
                                // let a=1;
                                // for (let i = 0; i < ctrlId.decimalPoint; i++) {
                                //     a=a*10;
                                // }
                                var titleArr = [];
                                var series = [];
                                HMIEchoData.historyData.forEach(element => {
                                    if (ctrlId.id == element.ctrlId) {
                                        element.data.forEach(ele => {
                                            titleArr.push(ele.name)
                                            // for (let j = 0; j < ele.data.length; j++) {
                                            //     ele.data[j][1]=ele.data[j][1]/a
                                            // }
                                            series.push({
                                                name: ele.name,
                                                type: "line",
                                                symbol: 'emptyCircle',
                                                data: ele.stream,
                                                connectNulls: true,
                                            })
                                        })
                                        series.forEach((item) => {
                                            if (item.data.length > 1) {
                                            let arrData=[]
                                            item.data.forEach((ite) => {
                                            let newArr = []
                                            for(let key in ite) {
                                        newArr.push(key, ite[key])
                                        }
                                        arrData.push(newArr)
                                        })
                                        item.data=arrData
                                          }
                                        })
                                        vDOM.option.series = series;
                                        vDOM.option.xAxis.data = element.date;
                                        vDOM.option.legend = {
                                            data: titleArr,
                                            y: "bottom",
                                            x: 'center'
                                        }
                                    }

                                });
                                // 设置option
                                vDOM.exterCtrl.resize();
                        
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }

                            break;
                        case 'title':
                            // vDOM.option.title.text = vValue+new Date().toLocaleDateString();
                            // 检查标题是否一致
                            if (vValue != vDOM.option.title.text) {
                                // 设置option
                                vDOM.option.title.text = vValue
                                // vDOM.option.title.text = vValue+new Date().toLocaleDateString();
                                 //历史曲线中显示时间
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'fontName':
                            // 检查字体是否一致
                            if (vValue != vDOM.option.textStyle.fontFamily) {
                                // 设置option字体
                                vDOM.option.textStyle.fontFamily = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'fontSize':
                            // 检查字体是否一致
                            if (vValue != vDOM.option.title.textStyle.fontSize) {
                                // 设置option字体
                                vDOM.option.title.textStyle.fontSize = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'max':
                            // 检查最大值是否一致
                            if (vValue != vDOM.option.yAxis.max) {
                                // 设置最大值
                                vDOM.option.yAxis.max = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                        case 'min':
                            // 检查最小值是否一致
                            if (vValue != vDOM.option.yAxis.min) {
                                // 设置最小值
                                vDOM.option.yAxis.min = vValue;
                                // 设置option
                                vDOM.exterCtrl.setOption(vDOM.option);
                            }
                            break;
                    }
                }
                break;


            default:
                //
                // 其他控件不支持此函数
                //
                return;
        }
    },
    //---------------------------------------------------------------------------------
    // 改变尺寸
    //
    resize: function (vExterCtrlsType, vDOM, width, height) {
        // 检查是否存在
        if (!vDOM.width) vDOM.width = width;
        if (!vDOM.height) vDOM.height = height;
        // 检查尺寸是否一致
        if ((vDOM.width === width) && (vDOM.height === height)) {
            // 返回
            return;
        }
        // 设置尺寸
        vDOM.width = width;
        vDOM.height = height;

        // 检查外部控件类型
        switch (vExterCtrlsType) {
            // 历史曲线控件
            case HG.ControlType.historyCurves:
            case HG.ControlType.clockDial:
            case HG.ControlType.warningTable:
            case HG.ControlType.dataTable:
                // 检查外部控件对象是否存在
                if (vDOM.exterCtrl) {
                    // 改变控件尺寸
                    vDOM.exterCtrl.resize({
                        width, height
                    });
                }
                break;
            default:
                //
                // 其他控件不支持此函数
                //
                return;
        }
    },
};

// 外部接口暴露
export default cExterCtrlsMG;